<template>
	<div>
		<!--
		<div class="menu">
			<router-link to="/">home</router-link>
			<router-link v-if="!isLoggedIn" to="/login">login</router-link>
			<router-link v-if="!isLoggedIn" to="/register">register</router-link>
			<router-link v-if="isLoggedIn" to="/profile">profile</router-link>
			<router-link v-if="isLoggedIn && currentUser.is_admin" to="/admin">admin</router-link>
			<span v-if="isLoggedIn"><a href="https://iwgame.g.bsrv.su/island">island</a></span>
			<span v-if="isLoggedIn"><a href="https://iwgame.g.bsrv.su/map">map</a></span>
			<span v-if="isLoggedIn"> | <a @click="logout">Logout</a></span>
		</div>
		<router-view/>
		-->
		
		<b-navbar toggleable="lg" type="dark" variant="dark" fixed="top">
			<div class="container">
				<router-link custom :to="{path: '/'}" v-slot="{href, route, navigate, isActive, isExactActive}">
					<b-navbar-brand :href="href" @click="navigate">
						<img src="//iwstatic.g.bsrv.su/img/panel/islandswars-lite-logo.svg" class="navbar-logo" title="Войны Островов" />
						<!--<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 953 953" width="32" height="32" version="1.1" shape-rendering="geometricPrecision" fill-rule="evenodd" clip-rule="evenodd" focusable="false" role="img" class="d-block" data-v-3d51269b=""><title data-v-3d51269b="">Войны Островов</title> <path fill="currentColor" d="M92 0h769c50 0 92 42 92 92v769c0 50-42 92-92 92H92c-50 0-92-42-92-92V92C0 42 42 0 92 0zm216 710c100 0 160-50 160-133 0-62-44-107-108-113v-3c48-8 86-52 86-102 0-71-55-117-140-117H111v468h197zM195 307h90c50 0 78 23 78 64 0 44-33 68-91 68h-77V307zm0 338V499h90c64 0 98 25 98 73s-33 73-94 73h-94zm503 65l163-468h-90L652 621h-2L531 242h-92l163 468h96z" data-v-3d51269b=""></path></svg>-->
					</b-navbar-brand>
				</router-link>
				<b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
				<b-collapse id="nav-collapse" is-nav>
					<b-navbar-nav>
						<!--
						<b-nav-item-dropdown text="GAME" left v-if="isLoggedIn && currentUser.is_admin">
							<b-dropdown-item href="https://iwgame.g.bsrv.su/island">Остров</b-dropdown-item>
							<b-dropdown-item href="https://iwgame.g.bsrv.su/map">Карта мира</b-dropdown-item>
							<b-dropdown-divider></b-dropdown-divider>
						</b-nav-item-dropdown>
						-->
						<router-link custom :to="{path: '/news'}" v-slot="{href, route, navigate, isActive, isExactActive}">
							<b-nav-item :href="href" @click="navigate" :class="[{'active': isExactActive}]">Новости</b-nav-item>
						</router-link>
						<router-link custom :to="{path: '/forum'}" v-slot="{href, route, navigate, isActive, isExactActive}">
							<b-nav-item :href="href" @click="navigate" :class="[{'active': isExactActive}]">Форум</b-nav-item>
						</router-link>
						<router-link custom :to="{path: '/wiki'}" v-slot="{href, route, navigate, isActive, isExactActive}">
							<b-nav-item :href="href" @click="navigate" :class="[{'active': isExactActive}]">Вики</b-nav-item>
						</router-link>
						<!--<b-nav-item href="#" disabled>Disabled</b-nav-item>-->
					</b-navbar-nav>
					<b-navbar-nav class="ml-auto">
						<b-nav-item-dropdown right v-if="isLoggedIn">
							<template v-slot:button-content>
								<em>{{currentUser.login}}</em>
							</template>
							<router-link custom :to="{path: '/admin'}" v-slot="{href, route, navigate, isActive, isExactActive}" v-if="isLoggedIn && currentUser.is_admin">
								<b-dropdown-item :href="href" @click="navigate" :class="[{'active': isExactActive}]">Админ-панель</b-dropdown-item>
							</router-link>
							<b-dropdown-divider></b-dropdown-divider>
							<router-link custom :to="{path: '/'}" v-slot="{href, route, navigate, isActive, isExactActive}">
								<b-dropdown-item :href="href" @click="navigate" :class="[{'active': isExactActive}]">Игровые миры</b-dropdown-item>
							</router-link>
							<router-link custom :to="{path: '/profile'}" v-slot="{href, route, navigate, isActive, isExactActive}">
								<b-dropdown-item :href="href" @click="navigate" :class="[{'active': isExactActive}]">Профиль</b-dropdown-item>
							</router-link>
							<b-dropdown-divider></b-dropdown-divider>
							<router-link custom :to="{path: '/logout'}" v-slot="{href, route, navigate, isActive, isExactActive}">
								<b-dropdown-item :href="href" @click="navigate">Выйти</b-dropdown-item>
							</router-link>
						</b-nav-item-dropdown>
						<router-link custom :to="{path: '/login'}" v-slot="{href, route, navigate, isActive, isExactActive}" v-if="!isLoggedIn">
							<b-nav-item :href="href" @click="navigate" :class="[{'active': isExactActive}]">Войти</b-nav-item>
						</router-link>
						<router-link custom :to="{path: '/register'}" v-slot="{href, route, navigate, isActive, isExactActive}" v-if="!isLoggedIn && $router.history.current.path != '/'">
							<b-nav-item :href="href" @click="navigate" :class="[{'active': isExactActive}]">Начать игру</b-nav-item>
						</router-link>
					</b-navbar-nav>
				</b-collapse>
			</div>
		</b-navbar>
		<div>
			<router-view/>
		</div>
		<footer class="py-3 bg-dark">
			<div class="container text-center">
				<div class="row">
					<div class="col-md-12">
						<div class="mobile-badges">
							<a href="javascript://" class="mobile-badge googleplay"></a>
							<a href="javascript://" class="mobile-badge appstore"></a>
						</div>
					</div>
				</div>
				<div class="row">
					<div class="col-md-12">
						<a href="https://games.platform.bsrv.su" target="_blank" class="bylexgames"></a>
						<a class="ico18"></a>
						<div class="footer-menu">
							<router-link to="/news">Новости</router-link>
							<router-link to="/forum">Форум</router-link>
							<router-link to="/rules">Правила  игры</router-link>
							<router-link to="/legal">Лицензионное соглашение</router-link>
							<br/>
							<router-link to="/wiki">Вики</router-link>
							<router-link to="/offer">Договор-оферта</router-link>
							<router-link to="/privacy">Политика конфиденциальности</router-link>
						</div>
						<div class="copyright text-white">&copy; byLex Games 2018-2021. Все права защищены.</div>
					</div>
				</div>
			</div>
		</footer>
	</div>
</template>

<style lang="css">
body {
	margin-top: 58px;
}

.navbar-logo {
	width: 32px;
	height: 32px;
}

.mobile-badges .mobile-badge {
	width: 150px;
	height: 54px;
	margin: 0 5px;
	border: 5px solid rgba(255, 255, 255, 0.5);
	-webkit-border-radius: 5px;
	-moz-border-radius: 5px;
	border-radius: 5px;
	display: inline-block;
	background-size: cover;
	background-repeat: no-repeat;
}
.mobile-badges .mobile-badge.googleplay {
	background-image: url('//iwstatic.g.bsrv.su/img/panel/googleplay-comingsoon-badge-ru.svg');
}
.mobile-badges .mobile-badge.appstore {
	background-image: url('//iwstatic.g.bsrv.su/img/panel/appstore-comingsoon-badge-ru.svg');
}

footer .bylexgames {
	margin-top: 5px;
	width: 60px;
	height: 60px;
	background-image: url(//iwstatic.g.bsrv.su/img/panel/bylexgames-logo.png);
	float: left;
	margin-left: 5px;
}
footer .ico18 {
	width: 65px;
	height: 65px;
	background-image: url(//iwstatic.g.bsrv.su/img/panel/ico18.png);
	float: right;
}
footer .footer-menu a {
	margin: 0 10px;
	text-decoration: underline;
	color: #fff;
}
footer .footer-menu a:hover {
	text-decoration: none;
}
</style>

<script>
//import { microtime, mt_rand, genId } from '../../lib.js';

//import QuickLinks from '../components/QuickLinks.vue';

export default {
	name: 'Layout',
	props: ['namespace'],
	data: () => ({
		
	}),
	buildStore: (state) => {
		if(state == null || state == undefined){
			state = {};
		}
		var res = {
			namespaced: true,
			state: () => {
				return state;
			},
			modules: {
				//"QuickLinks": QuickLinks.buildStore(),
			},
			mutations: {},
			actions: {},
		}
		return res;
	},
	computed: {
		model(){
			return this.getModel();
		},
		isLoggedIn(){
			return this.$store.getters.isLoggedIn;
		},
		currentUser(){
			return this.$store.state.user;
		},
	},
	methods: {
		
	},
	created(){
		this.$http.interceptors.response.use(undefined, function(err){
			return new Promise(function(resolve, reject){
				if(err.status === 401 && err.config && !err.config.__isRetryRequest){
					this.$store.dispatch('logout');
				}
				throw err;
			});
		});
	},
	components: {
		//QuickLinks: QuickLinks,
	},
}
</script>
