<template>
	<div>
		<div class="bg-sea pt-5">
			<div class="container">
				<div class="row align-items-center">
					<div class="col-md-12 py-5">
						<div class="play-now-btn py-5">
							<div class="abButton">
								<div class="iwIcon iwIcon-iwLogo iwLogo"></div>
								<div class="iwIcon buttonBackground"></div>
								<router-link custom :to="{path: '/register'}" v-slot="{href, route, navigate, isActive, isExactActive}">
									<div class="button" @click="navigate">
										<span><b>НАЧАТЬ ИГРУ</b></span>
									</div>
								</router-link>
								<div class="iwIcon iwIcon-playNowArrow playNowArrow"></div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="container">
			<div class="row">
				<div class="col-md-12 py-5">
					<div class="card border-primary text-center">
						<h3 class="card-header">ПОСТРОЙ СВОЮ ИМПЕРИЮ</h3>
						<div class="card-body">
							<p>Islands Wars — одна из лучших многопользовательских стратегий в твоем браузере!</p>
							<ul class="list-unstyled">
								<li>- Управляй островами</li>
								<li>- Распространяй влияние</li>
								<li>- Совершенствуй навыки дипломатии</li>
								<li>- Захватывай другие острова и побеждай</li>
							</ul>
						</div>
					</div>
					<div class="card border-primary text-center mt-5">
						<h3 class="card-header">ПРАВЬ ОСТРОВАМИ</h3>
						<div class="card-body">
							<p>Накапливай ресурсы, собирай дань и веди торговлю с другими игроками.</p>
							<p>Превращай свои деревни в города, улучшай армию и проводи празднования, чтобы твой народ был счастлив.</p>
							<p>Создавай объединения, атакуй разбойников и захватывай сокровища.</p>
						</div>
					</div>
					<div class="card border-primary text-center mt-5">
						<h3 class="card-header">ПРОСЛАВЬ СВОЕ ИМЯ</h3>
						<div class="card-body">
							<p>Действие игры Islands Wars происходит в мире античности.</p>
							<p>Ты начинаешь игру лидером местного племени, и твоя миссия — добиться лучшего будущего для твоего народа.</p>
							<p>Выбирай, кем ты хочешь стать — королем или губернатором, и координируй свою стратегию с другими игроками, чтобы установить контроль над ресурсами, армией и территорией.</p>
							<p>Немногие доживут до того момента, когда они смогут установить господство над всем миром.</p>
							<p><b>Станешь ли ты одним из них?</b></p>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<style lang="css" scoped>
.bg-sea {
	background: url('//iwstatic.g.bsrv.su/img/panel/bg06.png') no-repeat 0 45%;
	background-size: cover;
}

.play-now-btn {
	position: relative;
	width: 1px;
	margin: 0 auto;
}
.play-now-btn .abButton {
	display: inline-block;
	position: relative;
	height: 200px;
	width: 0;
}
.play-now-btn .abButton:before {
	content: '';
	position: absolute;
}
.play-now-btn .abButton:before {
	bottom: -25px;
	left: calc(50% - 218px);
	width: 437px;
	height: 250px;
	transform: perspective(100px) rotateX(-15deg);
	background: linear-gradient(to top,#FFF,rgba(255,255,255,0));
}
.play-now-btn .abButton .iwLogo {
	display: inline-block;
	position: relative;
	left: -117px;
	z-index: 1;
}
.iwIcon.iwIcon-iwLogo {
	background: url('//iwstatic.g.bsrv.su/img/panel/islandswars_logo.png') no-repeat;
	overflow: hidden;
	width: 234px;
	height: 177px;
}
.play-now-btn .abButton .buttonBackground {
	background: url('//iwstatic.g.bsrv.su/img/panel/sprite-1.png') no-repeat;
	/*background-position: 0 -132px;*/
	background-repeat: no-repeat;
	overflow: hidden;
	width: 420px;
	height: 104px;
	left: -210px;
	margin-top: -24px;
	margin-bottom: -80px;
}
.play-now-btn .abButton .buttonBackground {
	position: relative;
}
.play-now-btn .abButton .button {
	cursor: pointer;
	text-align: center;
	line-height: 34px;
	min-width: 272px;
	max-width: 300px;
	background-color: rgba(255,255,255,.4);
	color: #442A19;
	font-size: 22px;
	text-shadow: none;
	border: 1px solid #9A9389;
	box-shadow: inset 0 0 0 4px #fff, 0 0 0 1px #e8e5e1;
	padding: 10px;
	white-space: nowrap;
	transform: translateX(-50%);
}
.play-now-btn .abButton .button:hover {
	background-color: rgba(255,251,212,.8);
	box-shadow: inset 0 0 0 4px #fffbd4, 0 0 0 1px #e8e5e1;
}
.play-now-btn .abButton .button:active {
	background-color: rgba(255,251,212,.8);
	box-shadow: inset 0 0 0 4px #dad8d5, 0 0 0 1px #e8e5e1;
	background-image: linear-gradient(to top,rgba(255,255,255,0),#d6a865 90%);
}
.play-now-btn .abButton .playNowArrow {
	position: relative;
	top: 40px;
	left: -40px;
	transform: translateY(0);
	animation: playNow 1.1s linear infinite;
}
@keyframes playNow {
	15%, from {
		transform: translateY(0);
	}
	45% {
		transform: translateY(-15px);
	}
	57.5% {
		transform: translateY(-12px);
	}
	65% {
		transform: translateY(-15px);
	}
	to {
		transform: translateY(0);
	}
}
.iwIcon.iwIcon-playNowArrow {
	background-position: -598px -236px;
	background-repeat: no-repeat;
	overflow: hidden;
	width: 80px;
	height: 43px;
}
.iwIcon {
	background-image: url(//iwstatic.g.bsrv.su/img/panel/sprite.png);
}
</style>

<script>
export default {
	name: 'StartGame',
	props: ['namespace'],
	data: () => ({
		
    }),
	buildStore: (state) => {
		if(state == null || state == undefined){
			state = {
				
			};
		}
		
		var res = {
			namespaced: true,
			state: () => {
				return state;
			},
			modules: {},
			mutations: {},
		}
		
		return res;
	},
	computed: {
		model(){
			return this.getModel();
		},
		currentUser(){
			return this.$store.state.user;
		},
		isLoggedIn(){
			return this.$store.getters.isLoggedIn;
		},
	},
    methods: {},
}
</script>