<template>
	<div class="container pt-5 my-5">
		<header class="jumbotron">
			<h3>Замки</h3>
		</header>
		<p><b>Замки</b> - главные строения центральных островов в каждой части мира.</p>
		<p>В замке можно:
			<ul>
				<li>подать заявку на <router-link to="/wiki/opis_souz1">Регистрацию союза</router-link>;</li>
				<li>оплатить аренду торговой палатки;</li>
			</ul>
		</p>
	</div>
</template>

<style lang="css">

</style>

<script>
export default {
	name: 'WikiOpisZamki',
	computed: {
		currentUser(){
			return this.$store.state.user;
		},
	},
	/*mounted() {
		if (!this.currentUser) {
			this.$router.push('/login');
		}
	}*/
};
</script>