<template>
	<div class="container pt-5 my-5">
		<header class="jumbotron">
			<h3>Почта</h3>
		</header>
		<p class="text-center">
			<img src="//iwstatic.g.bsrv.su/resources/images/post1.jpg" width="150" height="106" /> <img src="//iwstatic.g.bsrv.su/resources/images/post5.jpg" width="150" height="106" />
		</p>
		<p><b>Почта</b> - общение в чате зависит от этого строения. Чем выше уровень строения, тем большее кол-во комнат может посетить пользователь. Также этот почта позволяет зарабатывать деньги, привлекая новых игроков в игру. Максимальное количество уровней - 10.</p>
		<p><b>Особенности строения:</b></p>
	</div>
</template>

<style lang="css">

</style>

<script>
export default {
	name: 'WikiOpisMail',
	computed: {
		currentUser(){
			return this.$store.state.user;
		},
	},
	/*mounted() {
		if (!this.currentUser) {
			this.$router.push('/login');
		}
	}*/
};
</script>