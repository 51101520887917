<template>
	<div class="container pt-5 my-5">
		<header class="jumbotron">
			<h3>Регистрация</h3>
		</header>
		<p>Наверное, не стоит много рассказывать о том, что же такое регистрация. Этого требуют в каждой игре. Мы только решили сделать эту процедуру максимально быстрой: Вам нужно указать только желаемый логин в игре (при условии, что он свободен), e-mail и пароль.</p>
	</div>
</template>

<style lang="css">

</style>

<script>
export default {
	name: 'WikiOpisReg',
	computed: {
		currentUser(){
			return this.$store.state.user;
		},
	},
	/*mounted() {
		if (!this.currentUser) {
			this.$router.push('/login');
		}
	}*/
};
</script>