<template>
	<div class="container pt-5 my-5">
		<header class="jumbotron">
			<h3>Дом</h3>
		</header>
		<p class="text-center">
			<img src="//iwstatic.g.bsrv.su/resources/images/house1.jpg" width="150" height="106" /> <img src="//iwstatic.g.bsrv.su/resources/images/house2.jpg" width="150" height="106" />
		</p>
		<p><b>Дом</b> - главное строение острова. В доме можно смотреть информаию о главном герое, одевать и снимать вещи и оружие, управлять и просматривать статистику острова, нанимать губернатора. Максимальное количество уровней - 6.</p>
		<p><b>Особенности строения:</b></p>
	</div>
</template>

<style lang="css">

</style>

<script>
export default {
	name: 'WikiOpisHouse',
	computed: {
		currentUser(){
			return this.$store.state.user;
		},
	},
	/*mounted() {
		if (!this.currentUser) {
			this.$router.push('/login');
		}
	}*/
};
</script>