<template>
	<div class="container pt-5 my-5">
		<header class="jumbotron">
			<h3>Мир, стороны света</h3>
		</header>
		<p>...</p>
	</div>
</template>

<style lang="css">

</style>

<script>
export default {
	name: 'WikiOpisWorld',
	computed: {
		currentUser(){
			return this.$store.state.user;
		},
	},
	/*mounted() {
		if (!this.currentUser) {
			this.$router.push('/login');
		}
	}*/
};
</script>