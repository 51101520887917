<template>
	<div class="container pt-5 my-5">
		<header class="jumbotron">
			<h3>Описание игры</h3>
		</header>
		<p>Онлайн игра <strong>«Islands Wars»</strong> — <strong>экономическая стратегия, RPG</strong> (англ. Role-playing game  — игра, где человек отыгрывает роль выбранного персонажа) в стиле <strong>фентези</strong>.</p>
		<p>Нам удалось совместить два жанра (<strong>стратегию и RPG</strong>), которые часто в играх используются разрозненно. Это дает возможность совместить две аудитории игроков, которые предпочитают тот или иной стиль игры. Особое внимание мы уделили графической составляющей, которая одновременно выглядит эстетично и позволяет комфортно играть даже при слабом качестве подключения к Интернет.</p>
		<p>Действие разворачивается на разбросанных среди океана островах. Каждый игрок самостоятельно выбирает расу: <strong><router-link to="/wiki/opis_elfs">эльфы</router-link>, <router-link to="/wiki/opis_bastards">бастарды</router-link>, <router-link to="/wiki/opis_valkiria">валькирии</router-link>, <router-link to="/wiki/opis_gnoms">гномы</router-link>, <router-link to="/wiki/opis_mert">мертвые</router-link>, <router-link to="/wiki/opis_goblins">гоблины</router-link>, <router-link to="/wiki/opis_trols">тролли</router-link></strong>; получает остров и развивает его — добывает ресурсы, создает инфраструктуру, готовит к атакам и обороне.</p>
		<p>Кроме острова, игрок развивает своего персонажа, а также может управлять армией и флотом. Развитие персонажа происходит путем:
			<ul>
				<li>Получения боевого опыта;</li>
				<li>Совершенствования своих умений:
					<ul>
						<li>Боевых, которые используются только в сражениях;</li>
						<li>Экономико-стратегических (не боевые), используются в развитии острова и персонажа в отдельности (вне боя). Например:
							<ul>
								<li>Добыча ресурсов;</li>
								<li>Кузнечное дело;</li>
								<li>Торговля;</li>
								<li>Пиратство;</li>
								<li>Разведка и др.</li>
							</ul>
						</li>
						<li>Продвижения по иерархическим лестницам. Например:
							<ul>
								<li>Титул;</li>
								<li>Склонность;</li>
							</ul>
						</li>
					</ul>
				</li>
			</ul>
		</p>
		<p>Четыре типа сражений позволяют каждому игроку найти для себя более подходящий вариант получения боевого опыта:
			<ul>
				<li>Атака/оборона острова (когда один игрок атакует остров с помощью своей армии, а другой обороняется);</li>
				<li>Дуэль (когда каждый игрок выставляет на бой только своего персонажа);</li>
				<li>Противостояние идеологий (противостояние Света и Тьмы);</li>
				<li>Сражения с ботами (выдуманными компьютерными персонажами); </li>
			</ul>
		</p>
	<p>Кроме развития своего острова, развития персональных умений, игроки могут объединяться в союзы. Союз помогает усилить оборону своего острова, дает возможность создавать более качественные предметы и вещи, позволяет пользоваться общими ресурсами. Помимо вышеперечисленного, объединения в союзы также увеличивают социальную активность игрока. Наряду с союзами, социальные связи игрок обретает с помощью чата, форума.</p>
	</div>
</template>

<style lang="css">

</style>

<script>
export default {
	name: 'WikiOpisGame',
	computed: {
		currentUser(){
			return this.$store.state.user;
		},
	},
	/*mounted() {
		if (!this.currentUser) {
			this.$router.push('/login');
		}
	}*/
};
</script>