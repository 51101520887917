<template>
	<div class="container pt-5 my-5">
		<div class="row align-items-center">
			<div class="col-md-4 offset-md-4">
				<div class="card h-100">
					<div class="card-body text-center">
						<img
							id="profile-img"
							src="//ssl.gstatic.com/accounts/ui/avatar_2x.png"
							class="profile-img-card"
						/>
						<p class="card-text">admin panel</p>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<style lang="css">

</style>

<script>
export default {
	name: 'Admin',
	props: ['namespace'],
	data: () => ({
		
    }),
	computed: {
		model(){
			return this.getModel();
		},
	},
	methods: {
		
	}
}
</script>