<template>
	<div class="container pt-5 my-5">
		<div class="row">
			<div class="col-md-4 offset-md-4">
				<div class="card h-100">
					<div class="card-body text-center">
						<form @submit.prevent="register">
							<div class="form-group">
								<label for="login">Логин</label>
								<input
									v-model="login"
									type="text"
									class="form-control"
									name="login"
									id="login"
									required
									autofocus
								/>
							</div>
							<div class="form-group">
								<label for="password">Пароль</label>
								<input
									v-model="password"
									type="password"
									class="form-control"
									name="password"
									id="password"
									required
								/>
							</div>
							<div class="form-group">
								<button type="submit" class="btn btn-primary btn-block">Регистрация</button>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<style lang="css">

</style>

<script>
//import User from '../models/user';

export default {
	name: 'Register',
	data: () => ({
		login: '',
		password: '',
		is_admin: null,
	}),
	methods: {
		register: function () {
			let data = {
				login: this.login,
				password: this.password,
				is_admin: this.is_admin,
			}
			this.$store.dispatch('register', data)
			.then(() => this.$router.push('/'))
			.catch(err => console.log(err))
		}
	}
};
</script>