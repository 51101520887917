<template>
	<div class="container pt-5 my-5">
		<header class="jumbotron">
			<h3>Банк</h3>
		</header>
		<p><b>Банк</b> позволяет:
			<ul>
				<li>обменять <router-link to="/wiki/opis_econom1">Голдланы</router-link> на <router-link to="/wiki/opis_econom1">Ланы</router-link>;</li>
				<li>подать заявку на вывод <router-link to="/wiki/opis_econom1">Голдланов</router-link> из игры;</li>
				<li>обналичить квитанцию, которая получена в результате <router-link to="/wiki/opis_wars5">Дропа</router-link>;</li>
			</ul>
		</p>
	</div>
</template>

<style lang="css">

</style>

<script>
export default {
	name: 'WikiOpisBank',
	computed: {
		currentUser(){
			return this.$store.state.user;
		},
	},
	/*mounted() {
		if (!this.currentUser) {
			this.$router.push('/login');
		}
	}*/
};
</script>