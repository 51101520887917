<template>
	<div>
		<b-card :title="post.title" :img-src="post.poster" class="mb-3" img-left v-if="post.poster">
			<b-card-text>{{post.cutcontent}}</b-card-text>
			<router-link class="card-link float-right stretched-link" :to="{path: post.url}">Подробнее</router-link>
			<b-card-text>{{post.datetime}}</b-card-text>
		</b-card>
		<b-card :title="post.title" class="mb-3" v-else>
			<b-card-text>{{post.cutcontent}}</b-card-text>
			<router-link class="card-link float-right stretched-link" :to="{path: post.url}">Подробнее</router-link>
			<b-card-text>{{post.datetime}}</b-card-text>
		</b-card>
	</div>
	
	<!--
	<div class="panel panel-default news-post">
		<img class="img-responsive" v-bind:src="post.poster" v-if="post.poster" />
		<div class="panel-heading" style="font-size: 23px;">
			<router-link :to="{path: post.url}">{{post.title}}</router-link>
		</div>
		<div class="panel-body">
			<p>{{post.cutcontent}}</p>
			<div class="pull-right">Добавлено: {{post.datetime}}</div>
			<div>Автор: {{post.author}}</div>
			<hr/>
			<div class="pull-right text-right">
				<div class="comments">
					<span><i class="fa fa-comments" style="margin-right: 10px;"></i>{{post.commentsText}}</span>
				</div>
				<div class="views">
					<span><i class="fa fa-eye" style="margin-right: 10px;"></i>{{post.viewsText}}</span>
				</div>
			</div>
			<router-link :to="{path: post.url}" class="btn btn-primary">Читать далее</router-link>
		</div>
	</div>
	-->
</template>

<style lang="scss" scoped>

</style>

<script>
export default {
	name: 'NewsPost',
	props: ['post'],
	buildStore: (state) => {
		if(state == null || state == undefined){
			state = {};
		}
		var res = {
			namespaced: true,
			state: () => {return state;},
			modules: {},
			mutations: {},
		}
		return res;
	},
	computed: {
		model(){
			return this.getModel();
		},
	},
	methods: {},
	components: {},
}
</script>