<template>
	<div class="container pt-5 my-5">
		<header class="jumbotron">
			<h3>Библиотека</h3>
		</header>
		<ul>
			<li><router-link to="/wiki/opis_reg">Регистрация</router-link></li>
			<li><router-link to="/wiki/copyright">Соглашение об использовании игры</router-link></li>
			<li><router-link to="/wiki/rules">Законы игры</router-link></li>
		</ul>
		<ul>
			<li><router-link to="/wiki/legend">Легенда</router-link></li>
			<li><router-link to="/wiki/opis_world">Мир, стороны света</router-link></li>
			<li><router-link to="/wiki/opis_zamki">Замки</router-link></li>
			<li><router-link to="/wiki/opis_game">Описание игры</router-link></li>
		</ul>
		<ul>
			<li>Расы (описание, особенности, характеристики):
				<ul>
					<li><router-link to="/wiki/opis_elfs">Эльфы</router-link></li>
					<li><router-link to="/wiki/opis_bastards">Бастарды</router-link></li>
					<li><router-link to="/wiki/opis_valkiria">Валькирии</router-link></li>
					<li><router-link to="/wiki/opis_gnoms">Гномы</router-link></li>
					<li><router-link to="/wiki/opis_mert">Мертвые</router-link></li>
					<li><router-link to="/wiki/opis_goblins">Гоблины</router-link></li>
					<li><router-link to="/wiki/opis_trols">Тролли</router-link></li>
					<li><router-link to="/wiki/opis_ghost">Призраки</router-link></li>
					<li><router-link to="/wiki/opis_angels">Ангелы</router-link></li>
					<li><router-link to="/wiki/opis_demons">Демоны</router-link></li>
					<li><router-link to="/wiki/opis_gods">Боги</router-link></li>
				</ul>
			</li>
			<li>Строения героя (описание, характеристики, апгрейд)
				<ul>
					<li><router-link to="/wiki/opis_academy">Академия</router-link></li>
					<li><router-link to="/wiki/opis_altar">Алтарь</router-link></li>
					<li><router-link to="/wiki/opis_auction">Аукцион</router-link></li>
					<li><router-link to="/wiki/opis_bank">Банк</router-link></li>
					<li><router-link to="/wiki/opis_house">Дом</router-link></li>
					<li><router-link to="/wiki/opis_dipcentre">Дипломатический центр</router-link></li>
					<li><router-link to="/wiki/opis_kazarma">Казарма</router-link></li>
					<li><router-link to="/wiki/opis_kamenelomka">Каменоломня</router-link></li>
					<li><router-link to="/wiki/opis_lesopilka">Лесопилка</router-link></li>
					<li><router-link to="/wiki/opis_shop">Государственный магазин</router-link></li>
					<li><router-link to="/wiki/opis_masterskaya">Кузница</router-link></li>
					<li><router-link to="/wiki/opis_port">Порт</router-link></li>
					<li><router-link to="/wiki/opis_mail">Почта</router-link></li>
					<li><router-link to="/wiki/opis_rinok">Рыночная площадь</router-link></li>
					<li><router-link to="/wiki/opis_sklad">Склад</router-link></li>
					<li><router-link to="/wiki/opis_tavern">Таверна</router-link></li>
					<li><router-link to="/wiki/opis_ferm">Ферма</router-link></li>
					<li><router-link to="/wiki/opis_fort">Форт</router-link></li>
					<li><router-link to="/wiki/opis_shahta">Шахта</router-link></li>
				</ul>
			</li>
			<li>Сражения:
				<ul>
					<li><router-link to="/wiki/opis_wars1">Противостояние между игроками (Захват и оборона острова)</router-link></li>
					<li><router-link to="/wiki/opis_wars2">Противостояние между союзами (Дуэли)</router-link></li>
					<li><router-link to="/wiki/opis_wars3">Противостояние между Тьмой и Светом</router-link></li>
					<li><router-link to="/wiki/opis_wars4">Противостояние между Богами Хаоса и игроками, Союзами, частями света</router-link></li>
					<li><router-link to="/wiki/opis_wars5">Дроп</router-link></li>
				</ul>
			</li>
			<li>Экономическая составляющая игры:
				<ul>
					<li><router-link to="/wiki/opis_econom1">Игровая валюта</router-link></li>
					<li>Профессии
						<ul>
							<li><router-link to="/wiki/opis_econom3">Шахтер</router-link></li>
							<li><router-link to="/wiki/opis_econom31">Лесоруб</router-link></li>
							<li><router-link to="/wiki/opis_econom4">Каменолом</router-link></li>
							<li><router-link to="/wiki/opis_econom10">Фермер</router-link></li>
							<li><router-link to="/wiki/opis_econom5">Мастер-оружейник</router-link></li>
							<li><router-link to="/wiki/opis_econom6">Мастер по броням</router-link></li>
							<li><router-link to="/wiki/opis_econom7">Мастер-ювелир</router-link></li>
							<li><router-link to="/wiki/opis_econom8">Торговец</router-link></li>
						</ul>
					</li>
					<li><router-link to="/wiki/opis_econom9">Крафт</router-link></li>
					<li><router-link to="/wiki/opis_econom11">Правила торговли</router-link></li>
				</ul>
			</li>
			<li>Союзы:
				<ul>
					<li><router-link to="/wiki/opis_souz0">Функции</router-link></li>
					<li><router-link to="/wiki/opis_souz1">Правила регистрации</router-link></li>
					<li><router-link to="/wiki/opis_souz2">Список союзов</router-link></li>
				</ul>
			</li>
			<li><router-link to="/wiki/opis_info">Информация об игроке</router-link></li>
			<li><router-link to="/wiki/opis_sclonnost">Склонность</router-link></li>
			<li>Вещи (описание, характеристики, модификация):
				<ul>
					<li><router-link to="/wiki/opis_shmot1">Оружие</router-link></li>
					<li><router-link to="/wiki/opis_shmot2">Щиты</router-link></li>
					<li><router-link to="/wiki/opis_shmot3">Шлемы</router-link></li>
					<li><router-link to="/wiki/opis_shmot4">Ожерелья</router-link></li>
					<li><router-link to="/wiki/opis_shmot5">Брони</router-link></li>
					<li><router-link to="/wiki/opis_shmot6">Штаны</router-link></li>
					<li><router-link to="/wiki/opis_shmot7">Ботинки</router-link></li>
					<li><router-link to="/wiki/opis_shmot8">Раритетные</router-link></li>
					<li><router-link to="/wiki/opis_shmot9">Артефактные</router-link></li>
				</ul>
			</li>
			<li>Юниты (описание, характеристики, модификация):
				<ul>
					<li>Боевые:
						<ul>
							<li><router-link to="/wiki/opis_unit1">Лучники</router-link></li>
							<li><router-link to="/wiki/opis_unit2">Копейщики</router-link></li>
							<li><router-link to="/wiki/opis_unit3">Дубинщики</router-link></li>
							<li><router-link to="/wiki/opis_unit4">Мечники</router-link></li>
							<li><router-link to="/wiki/opis_unit5">Топорщики</router-link></li>
						</ul>
					</li>
					<li>Рабочие:
						<ul>
							<li><router-link to="/wiki/opis_unit6">Простой рабочий</router-link></li>
							<li><router-link to="/wiki/opis_unit7">Инженер</router-link></li>
							<li><router-link to="/wiki/opis_unit10">Инженер-лесоруб</router-link></li>
							<li><router-link to="/wiki/opis_unit11">Инженер-каменолом</router-link></li>
							<li><router-link to="/wiki/opis_unit12">Инженер-фермер</router-link></li>
						</ul>
					</li>
				</ul>
			</li>
			<li><router-link to="/wiki/dilers">Дилеры</router-link></li>
		</ul>
	</div>
</template>

<style lang="css">

</style>

<script>
export default {
	name: 'Wiki',
	computed: {
		currentUser(){
			return this.$store.state.user;
		},
	},
	/*mounted() {
		if (!this.currentUser) {
			this.$router.push('/login');
		}
	}*/
};
</script>