<template>
	<div class="container pt-5 my-5">
		<header class="jumbotron">
			<h3>Аукцион</h3>
		</header>
		<p><b>Аукцион</b> позволяет продавать Вам своих юнитов за <router-link to="/wiki/opis_econom1">Ланы</router-link> и <router-link to="/wiki/opis_econom1">Голдланы</router-link></p>
		<p></p>
		<p></p>
	</div>
</template>

<style lang="css">

</style>

<script>
export default {
	name: 'WikiOpisAuction',
	computed: {
		currentUser(){
			return this.$store.state.user;
		},
	},
	/*mounted() {
		if (!this.currentUser) {
			this.$router.push('/login');
		}
	}*/
};
</script>