<template>
	<div class="container pt-5 my-5">
		<header class="jumbotron">
			<h3>Лицензионное соглашение</h3>
		</header>
		<p>Прежде чем зарегистрироваться и начать использовать игровой сервис "<router-link to="/">Islands Wars</router-link>", настоятально рекомендуем внимательно ознакомиться с настоящим Соглашением. В этом Соглашении регулируются права и обязанности всех субъектов игрового процесса.</p>
		<p>Соглашение вступает в силу с момента отсылки Вами заполненной электронной регистрационной формы или путем выбора и нажатия Пользователем опции "согласен" (или иной аналогичной опции).</p>
		<p>
			<ol>
				<li><b>Основные термины Соглашения.</b>
					<ul>
						<li>«<u>Пользователь</u>» - любое физическое или юридическое лицо (группа лиц), зарегистрированное на Сервисе и использующее его в некоммерческих личных целях, а также иное лицо получившее доступ к Игре и Сервису.</li>
						<li>«<u>Соглашение</u>» - Правила использования Сервиса "<router-link to="/">Islands Wars</router-link>" и определение ответственности между Администрацией Сервиса и Пользователем.</li>
						<li>«<u>Администрация</u>» - группа лиц, наделенных необходимыми для развития и поддержки Игры и Сервиса правами.</li>
						<li>«<u>Игра</u>» – бесплатная онлайн игра "<router-link to="/">Islands Wars</router-link>".</li>
						<li>«<u>Сервис игры</u>», «<u>Сервис</u>» - программное обеспечение онлайн игры и связанных с ней дополнительных услуг, доступ Пользователей к участию в Игре, к информационным ресурсам, предоставляемым в рамках Игры и иным мероприятиям, организовываемых Администрацией (и уполномоченными лицами) для Пользователей.</li>
						<li>«<u>Элементы игры</u>» - любой текст, фотографические изображения, образы, графика, а также любые данные в визуальной, аудио или аудиовизуальной, либо иной форме, находящиеся на сайте "<router-link to="/">Islands Wars</router-link>" или субдоменах. Также под этим термином следует понимать любые материалы, размещенные на каком-либо ином сайте или вэб-странице, имеющей ссылку на сайт "<router-link to="/">Islands Wars</router-link>".</li>
					</ul>
				</li>
				<li><b>Общие положения.</b>
					<ul class="list-unstyled">
						<li>2.1. Настоящее соглашение регулирует все отношения между Администрацией и Пользователем по отношению к Игре и Сервису игры.</li>
						<li>2.2. Описание Игры, а также ее Правила изложены в соответствующем разделе сайта "<router-link to="/">Islands Wars</router-link>" или субдомене являются неотъемлемой частью настоящего Соглашения и обязательны для Сторон.</li>
						<li>2.3. Пользователь, принимая условия настоящего Соглашения, подтверждает, что он правоспособен и дееспособен, и вправе заключать настоящее Соглашение.<br/><em>Платным сервисом Игры вправе пользоваться дееспособные Пользователи, достигшие возраста совершеннолетия. Лица, не достигшие возраста совершеннолетия, вправе пользоваться платным сервисом Игры в порядке и на условиях, определенных действующим законодательством Украины и нормами международного права.</em></li>
					</ul>
				</li>
				<li><b>Предмет Соглашения.</b>
					<ul class="list-unstyled">
						<li>3.1. В соответствии с условиями настоящего Соглашения Администрация предоставляет Пользователю использование Игры 24 часа в сутки, ежедневно, без перерывов, в соответствии с Правилами Игры и настоящим Соглашением, за исключением времени проведения профилактических работ, а также иных обстоятельств, препятствующих Пользователю осуществить доступ к Игре, возникших не по вине Администрации.</li>
					</ul>
				</li>
				<li><b>Авторское право. Защита интеллектуальной собственности.</b>
					<ul class="list-unstyled">
						<li>4.1. Права авторов, владельцев и других лиц, имеющих право использования Игры, размещенной на сайте "<router-link to="/">Islands Wars</router-link>", принадлежат на праве собственности Администрации и защищаются законодательством Украины и нормами международного права об охране интеллектуальной собственности и авторских правах.</li>
						<li>4.2. Авторские права Администрации распространяются на все элементы Игры.</li>
						<li>4.3. Запрещается копирование, тиражирование, воспроизводство, распространение и перевод на другие языки Игры, а также любого из элементов без письменного разрешения Администрации.</li>
						<li>4.4. Пользователь признает и соглашается с тем, что любой материал (целиком либо по частям) и все необходимые программы, связанные с Игрой, защищены законами об интеллектуальной собственности Украины и прочими международными законами, а также обязуется не нарушать такие права.</li>
						<li>4.5. Администрация предоставляет Пользователю личное неисключительное и непередаваемое право использовать Игру и программное обеспечение, предоставляемое в Игре, в соответствии с правилами Игры, при условии, что ни Пользователь, ни любые иные лица при содействии Пользователя не будут совершать действий:
							<ul class="list-unstyled ml-3">
								<li>- по копированию или изменению программного обеспечение Игры и Сервиса игры;</li>
								<li>- по созданию программ, производных от программного обеспечения Игры и Сервиса игры;</li>
								<li>- по проникновению в программное обеспечение с целью получения кодов программ;</li>
								<li>- по осуществлению продажи, уступки, сдачи в аренду, передачи третьим лицам в любой иной форме прав в отношении материала Игры и программного обеспечения Игры;</li>
								<li>- по модифицированию Сервиса, в том числе с целью получения несанкционированного доступа к нему;</li>
								<li>- и иных действий, аналогичных перечисленным выше и нарушающих права Администрации.</li>
							</ul>
						</li>
						<li>4.6. Пользователь несет ответственность за соблюдение прав (материальных и нематериальных) третьих лиц на информацию, переданную (предоставленную) Администрации или третьим лицам в пределах Игры.</li>
					</ul>
				</li>
				<li><b>Права и обязанности Пользователя.</b>
					<ul class="list-unstyled">
						<li>5.1. Права Пользователя.
							<ul class="list-unstyled ml-3">
								<li>5.1.1. Пользователь гарантирует, что используемое им оборудование и средства связи, в том числе используемые Пользователем услуги оператора связи являются достаточными и исправными для участия в Игре и пользование Сервисом.</li>
								<li>5.1.2. Пользователь имеет право пользоваться Игрой исключительно в некоммерческих личных целях в соответствии с правилами Игры.</li>
								<li>5.1.3. При регистрации Пользователь выбирает себе логин и пароль, который позволит идентифицировать Пользователя среди других пользователей Игры. Для доступа к платному сервису Пользователь обязан сообщить Администрации следующие достоверные данные о себе: свой логин в игре, дату рождения, адрес электронной почты.</li>
								<li>5.1.4. Пользователь имеет право на изменение своего пароля.</li>
								<li>5.1.5. Пользователь вправе зарегистрировать одного персонажа.</li>
								<li>5.1.6. Пользователь может направить претензии и предложения, связанные с использованием Игры, письменно либо по электронной почте по адресам, указанным на сайте: "<router-link to="/">Islands Wars</router-link>".</li>
								<li>5.1.7. Если для Пользователя не приемлемы какие-либо изменения и/или дополнения, он в праве немедленно прекратить использование Сервиса.</li>
								<li>5.1.8. Пользователь имеет право по своему усмотрению пользоваться платным Сервисом Игры. Пользователь допускается к платному Сервису с момента оплаты платного Сервиса</li>
							</ul>
						</li>
						<li>5.2. Обязанности Пользователя.
							<ul class="list-unstyled ml-3">
								<li>5.2.1. Пользователь самостоятельно за свой счет оплачивает доступ в сеть Интернет. Пользователь не имеет права передавать свою регистрацию (логин и пароль) третьему лицу, а также не имеете права получать его от третьего лица.</li>
								<li>5.2.2. Пользователь обязан самостоятельно обеспечивать неразглашение (тайну) своего пароля и иных необходимых данных и, соответственно, несет ответственность за сохранение и неразглашение своего пароля и другой конфиденциальной информации, а также все риски (убытки), связанные с этим.</li>
								<li>5.2.3. Пользователь обязуется соблюдать условия настоящего Соглашения. По требованию Администрации Пользователь обязан сообщить о себе достоверную информацию, позволяющую идентифицировать владельца персонажа.</li>
								<li>5.2.4. Пользователь имеет право осуществлять использование платного Сервиса только через уполномоченных представителей Администрации (далее дилеров). В случае если Пользователю станет известно о незаконных операциях (не через дилеров), он обязан немедленно сообщить об этом Администрации.</li>
								<li>5.2.5. Пользователь обязан в случае необходимости совершения каких-либо действий, связанных с использованием Игры, удостовериться, что он вступает в правоотношения с уполномоченным на то представителем Администрации.</li>
								<li>5.2.6. Пользователь не имеет права вмешиваться в программный код, несанкционированно получать доступ к компьютерной системе, получать, без надлежащего разрешения Администрации, доступ к базе данных пользователей игры или материалов сайта "<router-link to="/">Islands Wars</router-link>", использовать ошибки программного обеспечения, и обязан сообщать о них Администрации.</li>
								<li>5.2.7. Пользователь не имеет права распространять информацию, направленную на незаконное получение паролей доступа к персонажам Игры, а также распространять ссылки на сайты с подобной информацией.</li>
								<li>5.2.8. Пользователь обязуется уважительно и корректно относиться к другим пользователям Игры, а именно:
									<ul class="list-unstyled ml-3">
										<li>- не размещать материалы, откровенно противоречащие общественной морали и нравственности, лично оскорбляющие других игроков сообщества, модераторов или Администрацию, способствующие разжиганию межнациональной или международной розни;</li>
										<li>- не использовать ненормативную лексику; не угрожать насилием и физической расправой за пределами Игры;</li>
										<li>- не распространять каким-либо образом материалы, пропагандирующие либо выражающие неприятие или ненависть к какой-либо религии, культуре, расе, нации, народу, языку, политике, идеологии или общественному движению;</li>
										<li>- не передавать любую информацию или программное обеспечение, которое содержит в себе вирусы или иные вредные компоненты; совершать любые действия, которые противоречат нормам действующего законодательства или международного права;</li>
										<li>- не рекламировать порно-сайты, наркотики и ресурсы, содержащие подобную информацию, а также совершать иные подобные действия.</li>
									</ul>
								</li>
								<li>5.2.9. Пользователь не вправе ограничивать доступ других Пользователей к Игре или препятствовать другим Пользователям в использовании Игры.</li>
								<li>5.2.10. Пользователь обязуется не привлекать Администрацию ответчиком или соответчиком по любым обязательствам и расходам, связанным с нарушением данного Соглашения Пользователем или другими лицами, использующими его имя пользователя и пароль; или связанным с использованием Сервиса или сети Интернет; или связанным с помещением или передачей любого сообщения, информации, программного обеспечения или других материалов в сети Интернет Пользователем или другими лицами, использующими его имя пользователя и пароль.</li>
								<li>5.2.11. Если Пользователь получил от Администрации необоснованное сообщение (спам), ему следует незамедлительно известить об этом представителей Администрации.</li>
							</ul>
						</li>
					</ul>
				</li>
				<li><b>Права и обязанности Администрации.</b>
					<ul class="list-unstyled">
						<li>6.1. Администрация обеспечивает без взимания платы доступ Пользователя к Игре.</li>
						<li>6.2. Администрация может предоставлять Пользователю дополнительный платный сервис, перечень которого определяется настоящим Соглашением или Приложениями к нему. Порядок и условия пользования платным сервисом определены настоящим Соглашением.</li>
						<li>6.3. Администрация обязуется не разглашать преднамеренно информацию о Пользователе, предоставленную им при регистрации, третьим лицам, кроме случаев, предусмотренных законодательством, а также не изменять логин и пароль Пользователя без его согласия.</li>
						<li>6.4. Администрация не рассылает Пользователям необоснованные электронные или иные сообщения.</li>
						<li>6.5. Администрация не несет ответственности за временные технические сбои и перерывы в работе Игры, за временные сбои и перерывы в работе линий связи, иные аналогичные сбои, а также за неполадки компьютера, с которого Пользователь осуществляет выход в Интернет. Администрация также не предоставляет никаких гарантий, что полученное посредством Сервиса программное обеспечение или любые другие элементы не содержат вирусы и другие вредоносные компоненты.</li>
						<li>6.6. Администрация не несет ответственности за убытки, понесенные в результате использования или не использования Пользователем информации об Игре.</li>
						<li>6.7. Администрация обязуется обеспечивать исправность программных средств, используемых для приема информации о передаче денежных средств.</li>
						<li>6.8. Администрация имеет право в одностороннем порядке уменьшать игровой счет Пользователя при обнаружении недобросовестности действий Пользователя, приведших к необоснованному увеличению его игрового счета.</li>
						<li>6.9. Администрация не несет ответственности перед Пользователем за действия третьих лиц, в том числе других пользователей.</li>
						<li>6.10. Администрация имеет право на удаление или изменение любой информации, размещенной Пользователем на сайте "<router-link to="/">Islands Wars</router-link>" без предварительного согласования с Администрацией или не предусмотренной настоящим Соглашением, а также иными соглашениями с Администрацией.</li>
						<li>6.11. В случае нарушения Пользователем своих обязанностей по настоящему Соглашению, Администрация вправе в любой момент применить к нему санкции, выражающиеся в запрете Пользователю пользоваться Игрой, либо использовать к нему иные методы воздействия: предупреждение, временное блокирование пользования Игрой, требование о возмещении убытков и иные действия.</li>
						<li>6.12. В случае нарушения Пользователем любого из положений настоящего Соглашения Администрация вправе отказать ему в дальнейшем использовании Игры.</li>
						<li>6.13. Администрация вправе в одностороннем порядке и в любое время ограничить, расширить или изменить игровой мир, а также качество услуг без предварительного уведомления Пользователя.</li>
					</ul>
				</li>
				<li><b>Особые условия.</b>
					<ul class="list-unstyled">
						<li>7.1. Администрация не гарантирует, что программное обеспечение Игры не содержит ошибок или будет функционировать бесперебойно.</li>
						<li>7.2. Администрация не несет ответственности за убытки или иной вред, возникший у Пользователя в связи с действиями третьих лиц.</li>
						<li>7.3. Администрация оставляет за собой право удалять со своих серверов любую информацию или материалы, которые, по мнению Администрации, являются неприемлемыми, нежелательными или нарушающими настоящее Соглашение.</li>
						<li>7.4. Администрация вправе в одностороннем порядке и в любое время изменять условия настоящего Соглашения. Изменения вступают в силу с момента опубликования их на сайте Игры либо с иного момента, установленного Администрацией.</li>
						<li>7.5. Администрация не контролирует информацию, услуги и продукты, находящиеся в или предлагаемые посредством сети Интернет. Вследствие этого Пользователь принимает условие, в соответствии с которым все товары, информация и услуги, предлагаемые или доступные через Сервис или в сети Интернет (за исключением явно указанных как предоставляемые непосредственно Сервисом), предоставляются третьими сторонами, которые никак не связаны с Администрацией. Пользователь принимает на себя полную ответственность и риски за использование Сервиса и сети Интернет.</li>
						<li>7.6. Администрация не предоставляет никаких гарантий на любые товары, информацию и слуги, поставляемые посредством Сервиса или через сеть Интернет вообще.</li>
						<li>7.7. Администрация не будет нести ответственности за любые затраты или ущерб, прямо или косвенно возникшие в результате подобных поставок. Пользователь принимает условие, согласно которому он принимает на себя ответственность за оценку точности, полноты и пригодности всех мнений, оценок, услуг и другой информации, качества и функций товаров, предоставляемых посредством Сервиса или сети Интернет вообще.</li>
						<li>7.8. Настоящее Соглашение регулируется нормами законодательства Республики Казахстан и нормами международного права. Споры, возникающие из настоящего Соглашения, подлежат разрешению путем переговоров. В случае не урегулирования спора в досудебном порядке, он передается на разрешение в соответствующий с подсудностью, определяемой в соответствии с законодательством Украины, орган, если иное не предусмотрено нормами международного права.</li>
						<li>7.9. Использование дополнительного (платного) сервиса Игры осуществляется на основании настоящего Соглашения в порядке, определенном в правилах Игры.</li>
						<li>7.10. Платный Сервис может приобретаться только у лиц, имеющих разрешение Администрации на реализацию данных услуг. Пользователь несет все риски, связанные с приобретением им каких-либо услуг в нарушение порядка и условий, определенных настоящим Соглашением.</li>
					</ul>
				</li>
				<li><b>Вступление в силу и срок действия Соглашения.</b>
					<ul class="list-unstyled">
						<li>8.1. Настоящее Соглашение вступает в силу при акцептации его Пользователем на сайте "<router-link to="/">Islands Wars</router-link>" путем выбора и нажатия Пользователем опции "согласен" (или иной аналогичной опции) или с момента отсылки Пользователем заполненной электронной регистрационной формы. Пользователь соглашается, что выбор им вышеуказанной опции означает, что он предварительно и в полном объеме ознакомился с условиями настоящего Соглашения и принимает их без каких-либо исключений.</li>
						<li>8.2. Соглашение считается заключенным на неопределенный срок.</li>
						<li>8.3. Администрация вправе в одностороннем порядке отказаться от настоящего Соглашения и прекратить предоставление услуг по пользованию Игрой без возмещения убытков с предварительным уведомлением Пользователя о расторжении Соглашения не менее, чем за 1 месяц. Информирование может быть произведено путем опубликования данной информации на сайте "<router-link to="/">Islands Wars</router-link>".</li>
						<li>8.4. Все изменения, дополнения, приложения к настоящему Соглашению или иная информация являются неотъемлемой его частью и являются обязательными для исполнения с момента опубликования их на сайте "<router-link to="/">Islands Wars</router-link>" , если иное не установлено Администрацией.</li>
						<li>8.5. Настоящее Соглашение составлено на русском языке.</li>
						<li>8.6. Контактная информация: <a href="mailto:support-games@bylex.com">support-games@bylex.com</a>.</li>
					</ul>
				</li>
				<li><b>Предупреждение об эпилепсии.</b>
					<ul class="list-unstyled">
						<li>9.1. Пользование Сервисом может быть опасно для лиц, имеющих склонность к эпилепсии. Несоблюдение данного ограничения может повлечь за собой приступ эпилепсии и потерю сознания как следствие вспышек света или мелькающих изображений. Если у Вас или Ваших родственников когда-либо наблюдались эпилептические симптомы, то, прежде чем приступать к Игре, обязательно проконсультируйтесь с врачом.</li>
					</ul>
				</li>
			</ol>
		</p>
	</div>
</template>

<style lang="css">

</style>

<script>
export default {
	name: 'WikiCopyright',
	computed: {
		currentUser(){
			return this.$store.state.user;
		},
	},
	/*mounted() {
		if (!this.currentUser) {
			this.$router.push('/login');
		}
	}*/
};
</script>