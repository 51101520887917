<template>
	<div class="container pt-5 my-5">
		<header class="jumbotron">
			<h3>Валькирии</h3>
		</header>
		<p class="text-center">
			<img src="//iwstatic.g.bsrv.su/resources/avatars/big/valkiria.jpg" width="200" height="300" title="Валькирия" />
		</p>
		<p><b>Валькирии</b> - гордые воительницы из далёкого Замзибарья. Как и когда они появились в нашем мире, никто не помнит. Они всецело честны и отважны, никогда не отступают ни перед какими опасностями и трудностями. Даже <router-link to="/wiki/opis_trols">Тролли</router-link>, ужасные <router-link to="/wiki/opis_trols">Тролли</router-link> с опаской относятся к этой гордой и непреклонной расе.</p>
		<p><b>Специализация (отличительные особенности расы):</b></p>
		<p>Боевой навык - акробатика</p>
		<p><em>Описание навыка</em></p>
		<p>Экономический навык - лесоруб</p>
		<p><em>Описание навыка</em></p>
		<p>Навык кузнеца (атака) - мастер мечей</p>
		<p><em>Описание навыка</em></p>
		<p>Навык кузница (защита) - мастер шлемов</p>
		<p><em>Описание навыка</em></p>
	</div>
</template>

<style lang="css">

</style>

<script>
export default {
	name: 'WikiOpisValkiria',
	computed: {
		currentUser(){
			return this.$store.state.user;
		},
	},
	/*mounted() {
		if (!this.currentUser) {
			this.$router.push('/login');
		}
	}*/
};
</script>