<template>
	<div class="container pt-5 my-5">
		<header class="jumbotron">
			<h3>Шахта</h3>
		</header>
		<p class="text-center">
			<img src="//iwstatic.g.bsrv.su/resources/images/mine1.jpg" width="150" height="106" /> <img src="//iwstatic.g.bsrv.su/resources/images/mine5.jpg" width="150" height="106" />
		</p>
		<p><b>Шахта</b> - это строение позволяет совершать добычу угля. Максимальное количество уровней - 10.</p>
		<p><b>Особенности строения:</b></p>
	</div>
</template>

<style lang="css">

</style>

<script>
export default {
	name: 'WikiOpisShahta',
	computed: {
		currentUser(){
			return this.$store.state.user;
		},
	},
	/*mounted() {
		if (!this.currentUser) {
			this.$router.push('/login');
		}
	}*/
};
</script>