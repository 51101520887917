import Vue from 'vue';
import VueRouter from 'vue-router';

import {localStorageSet, localStorageGet, localStorageRemove} from './lib.js';

import Layout from './layouts/Layout.vue';
import Test from './layouts/Test.vue';

import Home from './pages/Home.vue';
import Login from './pages/Login.vue';
import Register from './pages/Register.vue';
import Profile from './pages/Profile.vue';
import Admin from './pages/Admin.vue';

import News from './pages/News.vue';
import NewsItem from './pages/NewsItem.vue';

import Forum from './pages/Forum.vue';
import Rules from './pages/Rules.vue';
import Legal from './pages/Legal.vue';

import Wiki from './pages/Wiki.vue';
import WikiLegend from './pages/wiki/Legend.vue';
import WikiOpisWorld from './pages/wiki/OpisWorld.vue';
import WikiOpisZamki from './pages/wiki/OpisZamki.vue';
import WikiOpisGame from './pages/wiki/OpisGame.vue';
import WikiOpisReg from './pages/wiki/OpisReg.vue';
import WikiCopyright from './pages/wiki/Copyright.vue';
import WikiRules from './pages/wiki/Rules.vue';
import WikiOpisElfs from './pages/wiki/OpisElfs.vue';
import WikiOpisBastards from './pages/wiki/OpisBastards.vue';
import WikiOpisValkiria from './pages/wiki/OpisValkiria.vue';
import WikiOpisGnoms from './pages/wiki/OpisGnoms.vue';
import WikiOpisMert from './pages/wiki/OpisMert.vue';
import WikiOpisGoblins from './pages/wiki/OpisGoblins.vue';
import WikiOpisTrols from './pages/wiki/OpisTrols.vue';
import WikiOpisGhost from './pages/wiki/OpisGhost.vue';
import WikiOpisAngels from './pages/wiki/OpisAngels.vue';
import WikiOpisDemons from './pages/wiki/OpisDemons.vue';
import WikiOpisGods from './pages/wiki/OpisGods.vue';
import WikiOpisAcademy from './pages/wiki/OpisAcademy.vue';
import WikiOpisAltar from './pages/wiki/OpisAltar.vue';
import WikiOpisAuction from './pages/wiki/OpisAuction.vue';
import WikiOpisBank from './pages/wiki/OpisBank.vue';
import WikiOpisHouse from './pages/wiki/OpisHouse.vue';
import WikiOpisDipcentre from './pages/wiki/OpisDipcentre.vue';
import WikiOpisKazarma from './pages/wiki/OpisKazarma.vue';
import WikiOpisKamenelomka from './pages/wiki/OpisKamenelomka.vue';
import WikiOpisLesopilka from './pages/wiki/OpisLesopilka.vue';
import WikiOpisShop from './pages/wiki/OpisShop.vue';
import WikiOpisMasterskaya from './pages/wiki/OpisMasterskaya.vue';
import WikiOpisPort from './pages/wiki/OpisPort.vue';
import WikiOpisMail from './pages/wiki/OpisMail.vue';
import WikiOpisRinok from './pages/wiki/OpisRinok.vue';
import WikiOpisSklad from './pages/wiki/OpisSklad.vue';
import WikiOpisTavern from './pages/wiki/OpisTavern.vue';
import WikiOpisFerm from './pages/wiki/OpisFerm.vue';
import WikiOpisFort from './pages/wiki/OpisFort.vue';
import WikiOpisShahta from './pages/wiki/OpisShahta.vue';
import WikiOpisWars1 from './pages/wiki/OpisWars1.vue';
import WikiOpisWars2 from './pages/wiki/OpisWars2.vue';
import WikiOpisWars3 from './pages/wiki/OpisWars3.vue';
import WikiOpisWars4 from './pages/wiki/OpisWars4.vue';
import WikiOpisWars5 from './pages/wiki/OpisWars5.vue';
import WikiOpisEconom1 from './pages/wiki/OpisEconom1.vue';
import WikiOpisEconom3 from './pages/wiki/OpisEconom3.vue';
import WikiOpisEconom31 from './pages/wiki/OpisEconom31.vue';
import WikiOpisEconom4 from './pages/wiki/OpisEconom4.vue';
import WikiOpisEconom10 from './pages/wiki/OpisEconom10.vue';
import WikiOpisEconom5 from './pages/wiki/OpisEconom5.vue';
import WikiOpisEconom6 from './pages/wiki/OpisEconom6.vue';
import WikiOpisEconom7 from './pages/wiki/OpisEconom7.vue';
import WikiOpisEconom8 from './pages/wiki/OpisEconom8.vue';
import WikiOpisEconom9 from './pages/wiki/OpisEconom9.vue';
import WikiOpisEconom11 from './pages/wiki/OpisEconom11.vue';
import WikiOpisSouz0 from './pages/wiki/OpisSouz0.vue';
import WikiOpisSouz1 from './pages/wiki/OpisSouz1.vue';
import WikiOpisSouz2 from './pages/wiki/OpisSouz2.vue';
import WikiOpisInfo from './pages/wiki/OpisInfo.vue';
import WikiOpisSclonnost from './pages/wiki/OpisSclonnost.vue';
import WikiOpisShmot1 from './pages/wiki/OpisShmot1.vue';
import WikiOpisShmot2 from './pages/wiki/OpisShmot2.vue';
import WikiOpisShmot3 from './pages/wiki/OpisShmot3.vue';
import WikiOpisShmot4 from './pages/wiki/OpisShmot4.vue';
import WikiOpisShmot5 from './pages/wiki/OpisShmot5.vue';
import WikiOpisShmot6 from './pages/wiki/OpisShmot6.vue';
import WikiOpisShmot7 from './pages/wiki/OpisShmot7.vue';
import WikiOpisShmot8 from './pages/wiki/OpisShmot8.vue';
import WikiOpisShmot9 from './pages/wiki/OpisShmot9.vue';
import WikiOpisUnit1 from './pages/wiki/OpisUnit1.vue';
import WikiOpisUnit2 from './pages/wiki/OpisUnit2.vue';
import WikiOpisUnit3 from './pages/wiki/OpisUnit3.vue';
import WikiOpisUnit4 from './pages/wiki/OpisUnit4.vue';
import WikiOpisUnit5 from './pages/wiki/OpisUnit5.vue';
import WikiOpisUnit6 from './pages/wiki/OpisUnit6.vue';
import WikiOpisUnit7 from './pages/wiki/OpisUnit7.vue';
import WikiOpisUnit10 from './pages/wiki/OpisUnit10.vue';
import WikiOpisUnit11 from './pages/wiki/OpisUnit11.vue';
import WikiOpisUnit12 from './pages/wiki/OpisUnit12.vue';
import WikiDilers from './pages/wiki/Dilers.vue';

import Offer from './pages/Offer.vue';
import Privacy from './pages/Privacy.vue';
import PageNotFound from './pages/PageNotFound.vue';

export default function(params)
{
	params["router"] = new VueRouter({
		mode: 'history',
		base: '/',
		routes:
		[
			/*{
				path: '/',
				component: StartGame,
				props: {default: true, namespace: ["page", "StartGame"]}
			},
			*/
			{
				path: '/',
				component: Layout,
				children: [
					{
						path: '',
						component: Home,
					},
					{
						path: '/login',
						component: Login,
						meta: {
							guest: true,
						},
					},
					{
						path: '/register',
						component: Register,
						meta: {
							guest: true,
						},
					},
					{
						path: '/profile',
						component: Profile,
						meta: {
							requiresAuth: true,
						},
					},
					{
						path: '/admin',
						component: Admin,
						meta: {
							requiresAuth: true,
							is_admin: true,
						},
					},
					{
						path: '/news',
						component: News,
					},
					{
						path: '/news/:id',
						component: NewsItem,
						props: {default: true, namespace: ["page", "NewsItem"]},
					},
					{
						path: '/forum',
						component: Forum,
					},
					{
						path: '/rules',
						component: Rules,
					},
					{
						path: '/legal',
						component: Legal,
					},
					{
						path: '/wiki',
						component: Wiki,
					},
					{
						path: '/wiki/legend',
						component: WikiLegend,
					},
					{
						path: '/wiki/opis_game',
						component: WikiOpisGame,
					},
					{
						path: '/wiki/opis_world',
						component: WikiOpisWorld,
					},
					{
						path: '/wiki/opis_zamki',
						component: WikiOpisZamki,
					},
					{
						path: '/wiki/opis_reg',
						component: WikiOpisReg,
					},
					{
						path: '/wiki/copyright',
						component: WikiCopyright,
					},
					{
						path: '/wiki/rules',
						component: WikiRules,
					},
					{
						path: '/wiki/opis_elfs',
						component: WikiOpisElfs,
					},
					{
						path: '/wiki/opis_bastards',
						component: WikiOpisBastards,
					},
					{
						path: '/wiki/opis_valkiria',
						component: WikiOpisValkiria,
					},
					{
						path: '/wiki/opis_gnoms',
						component: WikiOpisGnoms,
					},
					{
						path: '/wiki/opis_mert',
						component: WikiOpisMert,
					},
					{
						path: '/wiki/opis_goblins',
						component: WikiOpisGoblins,
					},
					{
						path: '/wiki/opis_trols',
						component: WikiOpisTrols,
					},
					{
						path: '/wiki/opis_ghost',
						component: WikiOpisGhost,
					},
					{
						path: '/wiki/opis_angels',
						component: WikiOpisAngels,
					},
					{
						path: '/wiki/opis_demons',
						component: WikiOpisDemons,
					},
					{
						path: '/wiki/opis_gods',
						component: WikiOpisGods,
					},
					{
						path: '/wiki/opis_academy',
						component: WikiOpisAcademy,
					},
					{
						path: '/wiki/opis_altar',
						component: WikiOpisAltar,
					},
					{
						path: '/wiki/opis_auction',
						component: WikiOpisAuction,
					},
					{
						path: '/wiki/opis_bank',
						component: WikiOpisBank,
					},
					{
						path: '/wiki/opis_house',
						component: WikiOpisHouse,
					},
					{
						path: '/wiki/opis_dipcentre',
						component: WikiOpisDipcentre,
					},
					{
						path: '/wiki/opis_kazarma',
						component: WikiOpisKazarma,
					},
					{
						path: '/wiki/opis_kamenelomka',
						component: WikiOpisKamenelomka,
					},
					{
						path: '/wiki/opis_lesopilka',
						component: WikiOpisLesopilka,
					},
					{
						path: '/wiki/opis_shop',
						component: WikiOpisShop,
					},
					{
						path: '/wiki/opis_masterskaya',
						component: WikiOpisMasterskaya,
					},
					{
						path: '/wiki/opis_port',
						component: WikiOpisPort,
					},
					{
						path: '/wiki/opis_mail',
						component: WikiOpisMail,
					},
					{
						path: '/wiki/opis_rinok',
						component: WikiOpisRinok,
					},
					{
						path: '/wiki/opis_sklad',
						component: WikiOpisSklad,
					},
					{
						path: '/wiki/opis_tavern',
						component: WikiOpisTavern,
					},
					{
						path: '/wiki/opis_ferm',
						component: WikiOpisFerm,
					},
					{
						path: '/wiki/opis_fort',
						component: WikiOpisFort,
					},
					{
						path: '/wiki/opis_shahta',
						component: WikiOpisShahta,
					},
					{
						path: '/wiki/opis_wars1',
						component: WikiOpisWars1,
					},
					{
						path: '/wiki/opis_wars2',
						component: WikiOpisWars2,
					},
					{
						path: '/wiki/opis_wars3',
						component: WikiOpisWars3,
					},
					{
						path: '/wiki/opis_wars4',
						component: WikiOpisWars4,
					},
					{
						path: '/wiki/opis_wars5',
						component: WikiOpisWars5,
					},
					{
						path: '/wiki/opis_econom1',
						component: WikiOpisEconom1,
					},
					{
						path: '/wiki/opis_econom3',
						component: WikiOpisEconom3,
					},
					{
						path: '/wiki/opis_econom31',
						component: WikiOpisEconom31,
					},
					{
						path: '/wiki/opis_econom4',
						component: WikiOpisEconom4,
					},
					{
						path: '/wiki/opis_econom10',
						component: WikiOpisEconom10,
					},
					{
						path: '/wiki/opis_econom5',
						component: WikiOpisEconom5,
					},
					{
						path: '/wiki/opis_econom6',
						component: WikiOpisEconom6,
					},
					{
						path: '/wiki/opis_econom7',
						component: WikiOpisEconom7,
					},
					{
						path: '/wiki/opis_econom8',
						component: WikiOpisEconom8,
					},
					{
						path: '/wiki/opis_econom9',
						component: WikiOpisEconom9,
					},
					{
						path: '/wiki/opis_econom11',
						component: WikiOpisEconom11,
					},
					{
						path: '/wiki/opis_souz0',
						component: WikiOpisSouz0,
					},
					{
						path: '/wiki/opis_souz1',
						component: WikiOpisSouz1,
					},
					{
						path: '/wiki/opis_souz2',
						component: WikiOpisSouz2,
					},
					{
						path: '/wiki/opis_info',
						component: WikiOpisInfo,
					},
					{
						path: '/wiki/opis_sclonnost',
						component: WikiOpisSclonnost,
					},
					{
						path: '/wiki/opis_shmot1',
						component: WikiOpisShmot1,
					},
					{
						path: '/wiki/opis_shmot2',
						component: WikiOpisShmot2,
					},
					{
						path: '/wiki/opis_shmot3',
						component: WikiOpisShmot3,
					},
					{
						path: '/wiki/opis_shmot4',
						component: WikiOpisShmot4,
					},
					{
						path: '/wiki/opis_shmot5',
						component: WikiOpisShmot5,
					},
					{
						path: '/wiki/opis_shmot6',
						component: WikiOpisShmot6,
					},
					{
						path: '/wiki/opis_shmot7',
						component: WikiOpisShmot7,
					},
					{
						path: '/wiki/opis_shmot8',
						component: WikiOpisShmot8,
					},
					{
						path: '/wiki/opis_shmot9',
						component: WikiOpisShmot9,
					},
					{
						path: '/wiki/opis_unit1',
						component: WikiOpisUnit1,
					},
					{
						path: '/wiki/opis_unit2',
						component: WikiOpisUnit2,
					},
					{
						path: '/wiki/opis_unit3',
						component: WikiOpisUnit3,
					},
					{
						path: '/wiki/opis_unit4',
						component: WikiOpisUnit4,
					},
					{
						path: '/wiki/opis_unit5',
						component: WikiOpisUnit5,
					},
					{
						path: '/wiki/opis_unit6',
						component: WikiOpisUnit6,
					},
					{
						path: '/wiki/opis_unit7',
						component: WikiOpisUnit7,
					},
					{
						path: '/wiki/opis_unit10',
						component: WikiOpisUnit10,
					},
					{
						path: '/wiki/opis_unit11',
						component: WikiOpisUnit11,
					},
					{
						path: '/wiki/opis_unit12',
						component: WikiOpisUnit12,
					},
					{
						component: WikiDilers,
						path: '/wiki/dilers',
					},
					{
						path: '/offer',
						component: Offer,
					},
					{
						path: '/privacy',
						component: Privacy,
					},
				],
				props: {default: true, namespace: ["page", "StartGame"]},
			},
			{
				path: '/logout',
				beforeEnter(to, from, next){
					params["store"].dispatch('logout').then(() => {
						next('/login');
					});
				},
			},
			{
				path: '/test',
				component: Test,
			},
			{
				path: '*',
				component: Layout,
				children: [
					{
						path: '',
						component: PageNotFound,
					},
				],
				props: {default: true, namespace: ["page", "PageNotFound"]},
			},
		],
		//linkActiveClass: 'active',
		//linkExactActiveClass: 'active',
	});
	
	params["store"].registerModule(["page", "Layout"], Layout.buildStore());
	//params["store"].registerModule(["page", "Home"], Home.buildStore());
	//params["store"].registerModule(["page", "Login"], Login.buildStore());
	//params["store"].registerModule(["page", "Register"], Register.buildStore());
	//params["store"].registerModule(["page", "Profile"], Profile.buildStore());
	//params["store"].registerModule(["page", "Admin"], Admin.buildStore());
	//params["store"].registerModule(["page", "News"], News.buildStore());
	//params["store"].registerModule(["page", "Forum"], Forum.buildStore());
	//params["store"].registerModule(["page", "Rules"], Rules.buildStore());
	//params["store"].registerModule(["page", "Legal"], Legal.buildStore());
	//params["store"].registerModule(["page", "Wiki"], Wiki.buildStore());
	//params["store"].registerModule(["page", "Offer"], Offer.buildStore());
	//params["store"].registerModule(["page", "Privacy"], Privacy.buildStore());
	//params["store"].registerModule(["page", "PageNotFound"], PageNotFound.buildStore());
	
	/*params["router"].beforeEach((to, from, next) => {
		params["store"].dispatch('checkAuth').then(() => {
			if(to.matched.some(record => record.meta.requiresAuth)){
				if(!params["store"].getters.isLoggedIn){
					next({
						path: '/login',
						params: {
							nextUrl: to.fullPath,
						},
					})
				} else {
					let user = localStorageGet('user')||{};
					if(to.matched.some(record => record.meta.is_admin)){
						if(user.is_admin){
							next()
						} else {
							next('/')
						}
					} else {
						next()
					}
				}
			} else if(to.matched.some(record => record.meta.guest)){
				if(!params["store"].getters.isLoggedIn){
					next()
				} else {
					next('/')
				}
			} else {
				next() 
			}
		});
	});*/
	
	return params;
}
