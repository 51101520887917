<template>
	<div class="container pt-5 my-5">
		<header class="jumbotron">
			<h3>Боги</h3>
		</header>
		<p><b>Светлые Боги</b> несут в себе Созидание, Любовь, Прощение. </p>
		<p><b>Темные Боги</b> - боги независимые, боги мятежные. Жадность, Зависть, Ненависть - вот то, что они приносят с собой.</p>
		<p><b>Боги Хаоса</b> - смыслом ихнего существования есть Разрушение. Где бы не появившись, они оставляют после себя разруху. Только собрав хорошую армию можно противостоять нашествию Хаоса.</p>
		<p><b>Нейтральные Боги</b> - этим богам нет дела до противостояний между Светом и Тьмой; им все равно на Хаос, который проносится смерчем по земле. Они управляют Стихиями: водой, землей, огнем и воздухом. Глупо было бы считать, что они посылают то одну Стихию, то другую в какие-то части мира для того, чтобы как-то повлиять на жителей этого мира. Но нельзя также говорить, что Стихии не влияют на положения дел в тех частях мира, где они находятся. Так, например, огонь может сжечь лес, а может увеличить урожай; ветер может увеличить скорость передвижение по карте Ваших кораблей или поднять такие волны, что плавать будет совершенно невозможно и т.д.</p>
	</div>
</template>

<style lang="css">

</style>

<script>
export default {
	name: 'WikiOpisGods',
	computed: {
		currentUser(){
			return this.$store.state.user;
		},
	},
	/*mounted() {
		if (!this.currentUser) {
			this.$router.push('/login');
		}
	}*/
};
</script>