<template>
	<div class="container pt-5 my-5">
		<div class="row">
			<div class="col-md-4 offset-md-4">
				<div class="card h-100">
					<div class="card-body text-center">
						<form class="login" @submit.prevent="login">
							<div class="form-group">
								<label for="login">Логин</label>
								<input
									v-model="userlogin"
									type="text"
									class="form-control"
									placeholder="login"
									name="login"
								/>
							</div>
							<div class="form-group">
								<label for="password">Пароль</label>
								<input
									v-model="password"
									type="password"
									class="form-control"
									placeholder="password"
									name="password"
								/>
							</div>
							<div class="form-group">
								<button class="btn btn-primary btn-block" type="submit">
									<span>{{btnLogin}}</span>
								</button>
							</div>
						</form>
						<div class="alert alert-warning" v-if="error">{{error}}</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<style lang="css">

</style>

<script>
export default {
	name: 'Login',
	props: ['namespace'],
	data: () => ({
		userlogin: '',
		password: '',
		btnLogin: 'Войти',
		error: '',
    }),
	computed: {
		model(){
			return this.getModel();
		},
	},
	methods: {
		login(){
			this.btnLogin = 'В процессе...'
			this.error = '';
			
			let userlogin = this.userlogin;
			let password = this.password;
			this.$store.dispatch('login', {login: userlogin, password})
			.then(() => {
				this.btnLogin = 'Войти';
				this.$router.push('/');
			})
			.catch(err => {
				this.btnLogin = 'Войти';
				console.log(err);
				this.error = err;
			});
		},
	}
}
</script>