<template>
	<div class="container pt-5 my-5">
		<header class="jumbotron">
			<h3>Тролли</h3>
		</header>
		<p class="text-center">
			<img src="//iwstatic.g.bsrv.su/resources/avatars/big/trolessa.jpg" width="200" height="300" title="Тролесса" /> <img src="//iwstatic.g.bsrv.su/resources/avatars/big/trol.jpg" width="200" height="300" title="Троль" />
		</p>
		<p><b>Тролли</b> - жители всех стран и островов стараются не иметь с ними дела, почему спросите Вы. Да как можно иметь дело, с существами, которое оценивает Вас как меню на обед, или запоздалый завтрак. Они не истребили себя только потому, что не могут есть себе подобных. Они свирепы и кровожадны, предпочитают в бою двуручную секиру или боевую дубину. Их шкура толста и служит им неплохими доспехами, ну а силу и мощь ударов им ни у кого не занимать. Они предпочитают кожаные доспехи из шкур убитых животных, но после встречи с <router-link to="/wiki/opis_gnoms">Гномами</router-link> не прочь обзавестись и кольчужкой, с убитого врага. Ну и что, что размерчик мал, главное что броня крепка. Тролли дети природы, они знают всё о животном мире, что позволяет им быть непревзойденными кожевниками.</p>
		<p><b>Специализация (отличительные особенности расы):</b></p>
		<p>Боевой навык - мускулатура</p>
		<p><em>Описание навыка</em></p>
		<p>Экономический навык - камнелом</p>
		<p><em>Описание навыка</em></p>
		<p>Навык кузнеца (атака) - мастер дубин</p>
		<p><em>Описание навыка</em></p>
		<p>Навык кузница (защита) - мастер брони</p>
		<p><em>Описание навыка</em></p>
	</div>
</template>

<style lang="css">

</style>

<script>
export default {
	name: 'WikiOpisTrols',
	computed: {
		currentUser(){
			return this.$store.state.user;
		},
	},
	/*mounted() {
		if (!this.currentUser) {
			this.$router.push('/login');
		}
	}*/
};
</script>