<template>
	<div>
		<servers v-if="isLoggedIn"></servers>
		<start-game v-else></start-game>
	</div>
</template>

<style lang="css" scoped>

</style>

<script>
import Servers from '../components/Servers.vue';
import StartGame from '../components/StartGame.vue';

export default {
	props: ['namespace'],
	data: () => ({
		
    }),
	buildStore: (state) => {
		if(state == null || state == undefined){
			state = {
				
			};
		}
		
		var res = {
			namespaced: true,
			state: () => {
				return state;
			},
			modules: {},
			mutations: {},
		}
		
		return res;
	},
	computed: {
		model(){
			return this.getModel();
		},
		currentUser(){
			return this.$store.state.user;
		},
		isLoggedIn(){
			return this.$store.getters.isLoggedIn;
		},
	},
    methods: {},
	components: {
		Servers,
		StartGame,
	},
}
</script>