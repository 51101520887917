<template>
	<div class="container pt-5 my-5">
		<header class="jumbotron">
			<h3>Кузница</h3>
		</header>
		<p class="text-center">
			<img src="//iwstatic.g.bsrv.su/resources/images/forge1.jpg" width="150" height="106" /> <img src="//iwstatic.g.bsrv.su/resources/images/forge5.jpg" width="150" height="106" />
		</p>
		<p><b>Кузница</b> - строение, в котором создаются, ремонтируются и улучшаются вещи. Максимальное количество уровней - 10.</p>
		<p><b>Особенности строения:</b></p>
	</div>
</template>

<style lang="css">

</style>

<script>
export default {
	name: 'WikiOpisMasterskaya',
	computed: {
		currentUser(){
			return this.$store.state.user;
		},
	},
	/*mounted() {
		if (!this.currentUser) {
			this.$router.push('/login');
		}
	}*/
};
</script>