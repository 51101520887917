<template>
	<div class="container pt-5 my-5">
		<header class="jumbotron">
			<h3>
				<strong>{{currentUser.login}}</strong> Profile
			</h3>
		</header>
		<p>
			<strong>Token:</strong>
			<!--{{currentUser.accessToken.substring(0, 20)}} ... {{currentUser.accessToken.substring(currentUser.accessToken.length - 20)}}-->
			{{currentToken.substring(0, 20)}} ... {{currentToken.substring(currentToken.length - 20)}}
		</p>
		<p>
			<strong>Id:</strong>
			{{currentUser.id}}
		</p>
		<p>
			<strong>Email:</strong>
			{{currentUser.email}}
		</p>
		<strong>Authorities:</strong>
		<ul>
			<li v-for="(role, index) in currentUser.roles" :key="index">{{role}}</li>
		</ul>
	</div>
</template>

<style lang="css">

</style>

<script>
export default {
	name: 'Profile',
	computed: {
		currentUser(){
			return this.$store.state.user;
		},
		currentToken(){
			return this.$store.state.token;
		},
	},
	/*mounted() {
		if (!this.currentUser) {
			this.$router.push('/login');
		}
	}*/
};
</script>