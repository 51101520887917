<template>
	<div class="container pt-5 my-5">
		<header class="jumbotron">
			<h4>Правила игры</h4>
		</header>
		<p>Правила игры дополняют Общие условия. Тебе также следует обратиться к общим условиям, особенно §11, если твоя учетная запись заблокирована, или если ты хочешь точно узнать, что является нарушением.</p>
		<p>Попытка обойти правила игры будет расцениваться как нарушение, и влечет за собой соответствующее наказание. Призыв, побуждение, воздействие, поощрение, помощь или сговор в целях нарушения правил игры Travian недопустимо. Все правила игры также применяются к игрокам, которые желают удалить учетную запись, или чьи учетные записи находятся в процессе удаления.</p>
		<h4>Определения:</h4>
		<ul>
			<li><b>Персонаж:</b> Персонаж - это твое воплощение в одном из игровых миров Travian Kingdoms, и он привязан к твоей учетной записи.</li>
			<li><b>Дуал:</b> Дуал-персонаж - это персонаж, которым играет сразу несколько игроков, чтобы поддерживать высокий онлайн. Они играют вместе и решают, что следует сделать с персонажем. Дуал-персонаж полезен, так как игроки могут сбалансировать сильные и слабые стороны друг друга, позволяя добиться большего успеха для персонажа. Кроме того, все игроки обычно имеют одинаковые права, что и владелец. Так что он тоже сможет тратить золото!</li>
			<li><b>Заместитель:</b> Заместитель - это игрок из твоего окружения, или даже лучше, из твоего королевства, который помогает тебе следить за персонажем. Если возможно, вам следует регулярно общаться друг с другом, чтобы договариваться об онлайне в разное время, чтобы во время твоего отсутствия заместитель более внимательно следил за персонажем. Кроме того, будет неплохо, если у каждого из вас будет своя задача (атака/защита).</li>
		</ul>
		<h4>§1. Учетная запись и персонаж</h4>
		<p>§1.1 Каждый игрок имеет право владеть только одной учетной записью. Название учетной записи можно изменить только через опцию редактирования, и оно может быть изменено только для одного из твоих персонажей. Имя персонажа изменить нельзя. Команда поддержки Travian может изменить имя персонажа, если оно нарушает правила игры.</p>
		<p>§1.2 Владельцем учетной записи считается игрок, чей электронный адрес прописан в настройках учетной записи. Он несет полную ответственность за все действия учетной записи или персонажа. Электронный адрес, который использовался для регистрации, должен находиться исключительно под личным контролем человека, который регистрировал учетную запись. Владельцем учетной записи признается владелец электронного адреса, вне зависимости от каких-либо иных соглашений. Если ты хочешь изменить электронный адрес, ты можешь сделать это в настройках учетной записи (//lobby.kingdoms.com/) через опцию редактирования. В случаях, когда возникают сомнения, Multihunter может запросить подтверждение владения электронным адресом. Изменение электронного адреса на другой рассматривается как передача прав на учетную запись новому владельцу. Перевод золота на другую учетную запись (в другое лобби) невозможен.</p>
		<p>§1.3 Передача пароля от учетной записи запрещена. Если ты хочешь использовать дуал-персонаж, тебе нужно добавить учетную запись дуала в настройках своего персонажа.</p>
		<p>§1.4 Персонажи, связанные функцией замещения, не должны использовать одинаковые пароли. Это же правило относится к персонажам или учетным записям, которые используют один компьютер для игры. Travian Games не компенсирует ущерб, нанесенный персонажу человеком, который получил пароль от учетной записи. Команда Travian Games никогда не спрашивает твой пароль.</p>
		<p>§1.5 Неактивный персонаж будет удален не раньше, чем через 7 дней с момента последнего входа в игру.</p>
		<p>§1.6 Использование временных электронных адресов не допускается и может привести к немедленному удалению.</p>
		<h4>§2. Замещение и использование одного компьютера</h4>
		<p>§2.1 Каждый владелец учетной записи может назначить одного или больше заместителей, которые могут играть персонажем в отсутствие владельца. Заместители должны использовать персонажа исключительно в интересах его владельца. Злоупотребление функцией замещения может вести к наказанию. Заместители персонажа должны входить в игру, используя пароль от своей собственной учетной записи.<br/>
		Travian Games не несет ответственности за ущерб, который был причинен действиями заместителя, и, соответственно, не компенсирует его никаким образом. Владелец учетной записи несет полную ответственность за действия заместителей. Если заместитель нарушает общие условия Travian Games, то могут быть наказаны обе учетные записи - как владельца, так и заместителя персонажа.</p>
		<p>§2.2 Игроки, которые используют один компьютер для игры, и желают помогать друг другу следить за учетными записями, должны использовать для этого функцию замещения.</p>
		<h4>§3. Использование сторонних приложений</h4>
		<p>Для игры в Travian Kingdoms разрешено использовать только официальное приложение Travian Kingdoms или немодифицированный распространенный браузер. Игра поддерживает Google Chrome, Safari, Firefox, Opera, Internet Explorer 10 и выше, за исключением альфа и бета версий. Travian Kingdoms не поддерживает мобильные приложения третьих лиц.</p>
		<p>Использование скриптов, ботов или приложений, автоматизирующих действия в игре, запрещено.</p>
		<p>Использование прокси-серверов или аналогичных инструментов не противоречит правилам игры, но не поддерживается. Если игрок не может войти в игру из-за использования прокси или аналогичных инструментов для IP адресов, Travian Games не будет рассматривать этот вопрос.</p>
		<h4>§4. Ошибки в игре</h4>
		<p>Запрещено использовать ошибки в игре как для как получения личной выгоды, так и для причинения вреда другим персонажам. Любое использование ошибок в игре запрещено и может привести к наказанию. Игроки, у которых возникла ошибка в игре, должны немедленно сообщить о ней службе поддержки.</p>
		<h4>§5. Передача денег и внутриигровой валюты</h4>
		<p>Продажа и покупка учетных записей, войск, деревень, ресурсов, услуг и любых других аспектов игры в Travian Kingdoms за реальные деньги запрещены. Не допускается продажа, а также любая непрямая передача (даже в качестве подарка) учетных записей Travian Kingdoms через сайты аукционов или с привлечением любых других способов передачи денег. Любые попытки манипулировать или злоупотребить аукционом внутри игры Travian Kingdoms с целью передачи игровой валюты от одного персонажа другому также не допускаются. Перевод золота на другую учетную запись (другое лобби) невозможен.</p>
		<h4>§6. Сетевой этикет</h4>
		<p>Русский язык является единственным официальным языком.</p>
		<p>Игроки должны придерживаться норм вежливости в общении.</p>
		<p>Недопустимые профили, названия деревень и имена героев могут быть отредактированы Multihunter сервера без предупреждения. Королевства, чьи названия или профили содержат неподобающие выражения или высказывания на языке, отличном от официального языка домена, могут быть удалены. То же относится к сообщениям на форуме и в других публичных местах.</p>
		<ul>
			<li>Следующее поведение строжайше запрещено: оскорбительные, унизительные, сексистские, расистские высказывания, использование ненормативной лексики; враждебные высказывания по поводу какой-либо религии, расы, национальности, пола, возрастной группы или сексуальной ориентации; угрозы реальной жизни; подражание официальным структурам Travian Kingdoms или кому-либо в компании.</li>
			<li>В игре не допускаются высказывания о реальной политике.</li>
			<li>Размещение или показ материала, который не пригоден для несовершеннолетних, также запрещены.</li>
			<li>Сообщения в игре могут быть опубликованы только с согласия обеих сторон. Публикация сообщений или писем от персонала Travian Kingdoms запрещена, если в них не содержится ясно выраженного согласия. Реклама любого рода также запрещена.</li>
		</ul>
		<h4>§7. Наказание</h4>
		<p>В случае нарушения правил игры или общих условий персонаж будет заблокирован/приостановлен и может быть освобожден только со штрафом, который выдается в соответствии с нарушением. Любые потери, понесенные во время блокировки/приостановки, включая потери Travian Plus и золота, возмещены не будут.</p>
		<p>Ко всем игрокам в игре применяется равное отношение, вне зависимости от приобретенного ими золота. Намеренное нарушение правил игры Travian Kingdoms, игровой механики или любой другой процедуры в Travian Kingdoms для получения собственной выгоды или для причинения ущерба другим игрокам недопустимо.</p>
		<p>Блокировку/Приостановку можно обсуждать только с Multihunter через Travian Kingdoms Help Center. Если игрок не согласен с решением Multihunter, он может обратиться по электронной почте к Community Manager. Блокировка, приостановка или удаление и их последствия не могут обсуждаться публично. Любая информация, касающаяся мер, примененных к персонажу, может быть выдана только владельцу учетной записи. Это означает, что команда Travian Kingdoms отвечает на запросы, которые отправлены исключительно через Travian Kingdoms Help Center и только владельцем учетной записи, а также запросы, отправленные с адреса регистрации учетной записи.</p>
		<p>Мультиаккаунты на скоростном сервере с населением меньше или равному 100 могут быть удалены без предварительной блокировки/предупреждения.</p>
		<h4>§8. Изменения и поправки в правилах</h4>
		<p>Travian оставляет за собой право вносить изменения в правила в любое время. Если какие-либо параграфы данного станут недействительными или будут удалены, то это не повлияет на действительность остальных параграфов данного документа. Комьюнити-менеджеры обязуются заменять недействительные параграфы новыми в максимально сжатые сроки.</p>
	</div>
</template>

<style lang="css">

</style>

<script>
export default {
	name: 'Rules',
	computed: {
		currentUser(){
			return this.$store.state.user;
		},
	},
	/*mounted() {
		if (!this.currentUser) {
			this.$router.push('/login');
		}
	}*/
};
</script>