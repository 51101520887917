<template>
	<div class="container pt-5 my-5">
		<header class="jumbotron">
			<h3>Законы игры</h3>
		</header>
		<p><b>Виды нарушений:</b>
			<ol>
				<li><b>Мультичаринг (регистрация нескольких персонажей одним человеком)</b></li>
				<li><b>Нарушения, связанные с регистрацией персонажа:</b>
					<ul class="list-unstyled">
						<li>- указание неверных данных в полях: имя, дата рождения;</li>
						<li>- указание неверного или несуществующего адреса почтового ящика (e-mail);</li>
						<li>- создание ников, содержащих ненормативную лексику или завуалированный мат;</li>
					</ul>
				</li>
				<li><b>Незаконные торговые операции с объектами игровой среды Проекта:</b>
					<ul class="list-unstyled">
						<li>- покупка/продажа персонажей/игровой валюты/вещей/артефактов за реальные деньги в обход Коммерческого отдела;</li>
						<li>- покупка/продажа предметов и услуг, не имеющих отношения к Проекту, за объекты игровой среды Проекта;</li>
					</ul>
				</li>
				<li><b>Нарушения правил общения в чате и на форуме:</b>
					<ul class="list-unstyled">
						<li>- флуд/спам в чате/форуме (создание помех в общении другим игрокам, повторение однотипных сообщений в общий канал чата и приваты игроков (3 и больше раз));</li>
						<li>- флуд/спам в чате смайлами (не более трех);</li>
						<li>- злоупотребление клавишей Caps Lock (написание сообщение на форуме или в чате заглавными буквами (больше двух поряд или больше 3 в одном предложении, не учитывая общепринятых аббревиатур));</li>
						<li>- обсуждение или реклама других игровых или коммерческих онлайн проектов;</li>
						<li>- распространение ссылок на ресурсы, не имеющие отношение к Проекту, рекламных ссылок, сообщений рекламирующих товары и услуги, не имеющие отношение к Проекту;</li>
						<li>- пропаганда или обсуждение наркотиков, порнографии, пропаганда расизма, национализма, разжигание межнациональной и межконфессиальной розни;</li>
						<li>- сексуальное домогательство (при наличии заявления потерпевшего);</li>
						<li>- подстрекательство к нарушению данного кодекса;</li>
						<li>- разглашение конфиденциальной информации (адреса и другие данные о реальной жизни игроков, информация о Проекте), запрещенной к разглашению, и прочее;</li>
						<li>- дискриминация (по расовой, религиозной, половой принадлежности и прочее);</li>
						<li>- оскорбительные высказывания в адрес Администрации или самого проекта, игрока/игроков, Призраков, унижение их чести и достоинства в любой форме;</li>
						<li>- использование ненормативной лексики (в том числе завуалированного мата - пропущенные буквы, замена букв на другие символы);</li>
						<li>- обсуждение действий Призраков вне специального раздела форума;</li>
						<li>- попрошайничество в любой форме;</li>
					</ul>
				</li>
				<li><b>Прокачка:</b>
					<ul class="list-unstyled">
						<li>- проведение договорных боев (проведение боев за вознаграждение в денежной или любой иной форме);</li>
						<li>- проведение множественных боев между одними и теми же персонажами в короткий промежуток времени;</li>
						<li>- умышленный проигрыш одного персонажа другому (старшего уровня младшему, младшего уровня старшему, одного уровня);</li>
						<li>- слив финансовых средств посредством лицензии торговца;</li>
						<li>- безвозмездная передача монет (вещей) от неиграющего персонажа другому или члену сообщества, если персонаж находится в таковом;</li>
					</ul>
				</li>
				<li><b>Использование и распространение программ-ботов.</b></li>
				<li><b>Обман Администрации, Призраков, игроков проекта:</b>
					<ul class="list-unstyled">
						<li>- обман игроков (мошенничество в любых формах, невыполнение условий сделки или других договоренностей подтвержденных на Форуме, хищение чужого имущества);</li>
						<li>- вымогательство/шантаж (принуждение к совершению сделки);</li>
						<li>- обман Администрации, Призраков в вопросах касающихся нарушения законов Проекта;</li>
						<li>- клевета на игроков, Призраков, Администрацию;</li>
					</ul>
				</li>
				<li><b>Перенос игровых конфликтов в реальную жизнь.</b></li>
				<li><b>Диверсионная деятельность, использование и сокрытие ошибок программного обеспечения Проекта:</b>
					<ul class="list-unstyled">
						<li>- ДОС-атаки, использование ошибок программного обеспечения (багов);</li>
						<li>- распространение ссылок на веб-сайты, содержание которых вызывает сбои в работе компьютера, а равно на веб-сайты, содержание которых направлено на незаконное получение паролей доступа к персонажам;</li>
						<li>- взлом/ хищение персонажей;</li>
						<li>- сокрытие информации о багах от Администрации проекта;</li>
					</ul>
				</li>
				<li><b>Нарушения, связанные с превышением Призраками своих полномочий и использованием служебного положения в личных целях:</b>
					<ul class="list-unstyled">
						<li>- вымогательство взятки;</li>
						<li>- получение взятки (получение монет/вещей за действие или бездействие Призрака);</li>
						<li>- разглашение служебной информации и конфиденциальных сведений, полученных в процессе работы или от Администрации;</li>
						<li>- злоупотребление служебными полномочиями/использование их в личных целях (действия Призрака, совершенные из корысти или личной заинтересованности);</li>
						<li>- превышение должностных полномочий (действия Призрака, явно выходящие за пределы его полномочий);</li>
						<li>- халатность в работе;</li>
					</ul>
				</li>
				<li><b>Создание помех в работе судопроизводства:</b>
					<ul class="list-unstyled">
						<li>- попытка дачи взятки должностному лицу;</li>
						<li>- флуд в рабочем топике Призраков;</li>
						<li>- попытка принуждения (шантаж, угрозы) Призрака к совершению каких-либо действий;</li>
						<li>- предоставление заведомо ложных или сфабрикованных сведений/доказательств Призрака, умышленный ввод их в заблуждение, отказ в предоставлении им необходимой информации;</li>
					</ul>
				</li>
			</ol>
		</p>
		<p><b>Нарушения, ответственность и наказания:</b>
			<ol>
				<li><b>Под нарушением подразумевается любое действие или бездействие, запрещенное данным кодексом в Разделе «Виды нарушений».</b><b>Ответственность за совершенное нарушение несет игровой персонаж, независимо от того, кто фактически осуществлял управление персонажем.</b></li>
				<li><b>При выборе наказания учитывается тяжесть нарушения и степень виновности владельца персонажа.</b></li>
				<li><b>Смягчающими обстоятельствами являются:</b>
					<ul class="list-unstyled">
						<li>- нарушение Закона впервые и признание своей ошибки;</li>
					</ul>
				</li>
				<li><b>Отягщающими обстоятельствами являются:</b>
					<ul class="list-unstyled">
						<li>- рецидив (совершение в течение суток нарушения лицом, уже понесшим ранее наказание за нарушение подобного рода);</li>
					</ul>
				</li>
				<li><b>Наказание за совершение нарушения назначается Призраками и Администрацией.</b></li>
				<li><b>Наказание по совокупности нарушений назначается путем сложения наказаний за каждое нарушение.</b></li>
				<li><b>Виды наказаний:</b>
					<ul class="list-unstyled">
						<li>- предупреждение;</li>
						<li>- наложение заклятья «Молчание» (15 мин, 1 час, 3 часа, 6 часов, сутки );</li>
						<li>- блокировка персонажа;</li>
						<li>- удаление персонажа из базы;</li>
						<li>- бан ip игрока;</li>
						<li>- понижение Призрака в должности;</li>
						<li>- лишение статуса Призрака;</li>
						<li>- исключение из состава Администрации;</li>
					</ul>
				</li>
			</ol>
		</p>
	</div>
</template>

<style lang="css">

</style>

<script>
export default {
	name: 'WikiRules',
	computed: {
		currentUser(){
			return this.$store.state.user;
		},
	},
	/*mounted() {
		if (!this.currentUser) {
			this.$router.push('/login');
		}
	}*/
};
</script>