<template>
	<div class="container mt-5">
		
		<b-breadcrumb>
			<b-breadcrumb-item href="#home">Home</b-breadcrumb-item>
			<b-breadcrumb-item href="#foo">Foo</b-breadcrumb-item>
			<b-breadcrumb-item href="#bar">Bar</b-breadcrumb-item>
			<b-breadcrumb-item active>Baz</b-breadcrumb-item>
		</b-breadcrumb>
		
		<!--
		<font-awesome-icon :icon="['fab', 'telegram']"></font-awesome>
		<font-awesome-icon icon="eye"></font-awesome>
		-->
	</div>
</template>

<style lang="css">
	
</style>

<script>
export default {
	name: 'Test',
	data: () => ({
		
	}),
	computed: {},
	methods: {},
	created(){},
	components: {
		
	},
}
</script>
