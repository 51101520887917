<template>
	<div class="container pt-5 my-5">
		<header class="jumbotron">
			<h3>Новости</h3>
		</header>
		<div class="row">
			<div class="col-md-12">
				<b-overlay :show="newsOverlay" rounded="lg" variant="white">
					<form v-on:submit.prevent="setPage(1)" style="margin-bottom: 20px;">
						<input type="text" v-model="newsPostsSearchText" class="form-control" placeholder="Поиск" />
					</form>
					<div class="row">
						<div class="col-md-12">
							<news-post
								v-for="post in newsPosts"
								v-bind:key="post.id"
								v-bind:post="post"
							></news-post>
							
							<nav>
								<ul class="pagination justify-content-center">
									<li v-for="p in pagination.pages" @click.prevent="setPage(p)" v-bind:class="['page-item', {active: p == pagination.currentPage}]">
										<a class="page-link" href="#" v-if="p != pagination.currentPage">{{p}}</a>
										<span class="page-link" v-else>{{p}}</span>
									</li>
								</ul>
							</nav>
							<div class="text-center">
								<div>Показано с {{pagination.startIndex + 1}} по {{pagination.endIndex + 1}} из {{newsPostsCount}} записей</div>
							</div>
						</div>
					</div>
				</b-overlay>
			</div>
		</div>
	</div>
</template>

<style lang="css">
.card-img-left {
	width: 300px;
}
</style>

<script>
import NewsPost from '../components/NewsPost.vue';
import { decl1, decl2 } from '../assets/js/scripts.js';
import _ from 'lodash';

export default {
	name: 'News',
	data: () => ({
		newsOverlay: false,
		newsPostsSearchText: '',
		newsPosts: [],
		newsPostsCount: 0,
		newsPostsLimit: 12,
		pagination: {
			startIndex: 0,
			endIndex: 0,
			currentPage: 0,
			pages: [],
		},
	}),
	methods: {
		setPage(p){
			if(p != this.pagination.currentPage){
				this.newsOverlay = true;
				fetch('/api/news?' + new URLSearchParams({
					search: this.newsPostsSearchText,
					limit: 1,
				}))
					.then(stream => stream.json())
					.then(data => {
						this.newsPostsCount = data.count;
						this.pagination = this.paginator(this.newsPostsCount, p);
						this.scrollToTop();
						setTimeout(() => {
							this.newsOverlay = false;
						}, 1000);
					})
					.catch(error => console.error(error));
			}
		},
		paginator(totalItems, currentPage){
			var startIndex = (currentPage - 1) * this.newsPostsLimit;
			var endIndex = Math.min(startIndex + this.newsPostsLimit - 1, totalItems - 1);
			
			this.newsOverlay = true;
			fetch('/api/news?' + new URLSearchParams({
				search: this.newsPostsSearchText,
				start: startIndex,
				limit: this.newsPostsLimit,
			}))
				.then(stream => stream.json())
				.then(data => {
					data.posts.forEach(item => {
						item.url = '/news/'+item.id;
						item.poster = '//iwstatic.g.bsrv.su/img/news/'+item.id+'/'+item.poster;
						item.datetime = new Date(item.ugmtime_public).format('d.m.Y');
						item.commentsText = parseInt(item.comments) > 0 ? item.comments+' '+decl1(item.comments, ['комментарий', 'комментария', 'комментариев']) : 'Комментариев нет';
						item.viewsText = parseInt(item.views) > 0 ? item.views+' '+decl1(item.views, ['просмотр', 'просмотра', 'просмотров']) : 'Просмотров нет';
						item.likesText = parseInt(item.likes) > 0 ? item.likes+' '+decl1(item.likes, ['лайк', 'лайка', 'лайков']) : 'Лайков нет';
					});
					this.newsPosts = data.posts;
					setTimeout(() => {
						this.newsOverlay = false;
					}, 1000);
				})
				.catch(error => console.error(error));
			
			return {
				currentPage: currentPage,
				startIndex: startIndex,
				endIndex: endIndex,
				pages: _.range(1, Math.ceil(totalItems / this.newsPostsLimit) + 1),
			};
		},
		scrollToTop(){
			window.scrollTo(0, 0);
		},
	},
	beforeMount(){
		this.setPage(1);
	},
	computed: {
		model(){
			return this.getModel();
		},
		currentUser(){
			return this.$store.state.user;
		},
	},
	/*mounted() {
		if (!this.currentUser) {
			this.$router.push('/login');
		}
	}*/
	components: {
		NewsPost: NewsPost,
	},
};
</script>