import Vue from 'vue'
import Vuex from 'vuex'

import {getCookie, setCookie, deleteCookie, localStorageSet, localStorageGet, localStorageRemove} from '../lib.js';

let store = function(params){
	params["store"] = new Vuex.Store({
		state: {
			authChecked: false,
			status: '',
			token: localStorageGet('token')||'',
			user: localStorageGet('user')||{},
		},
		actions: {
			checkAuth({commit, dispatch}){
				return new Promise((resolve, reject) => {
					if(!params["store"].state.authChecked && params["store"].state.status == ''){
						commit('auth_request');
						
						fetch('https://sso.platform.bsrv.su/auth/check.auth.php', {
							mode: 'cors',
							cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
							credentials: 'include', // include, *same-origin, omit
							method: 'POST',
							body: JSON.stringify({
								resultType: 'json',
							}),
							headers: {
								'Content-Type': 'application/json'
							},
						}).then(stream => stream.json()).then(data => {
							//console.log(data);
							//console.log('auth check done');
							
							if(data.error_code == 0){
								// session expired or not exists. logout
								localStorageRemove('token');
								localStorageRemove('user');
								dispatch('logout');
								resolve();
								return true;
							} else {
								if(params["store"].state.user.login_hash == data.hash){
									resolve();
									return true;
								}
								
								fetch('https://sso.platform.bsrv.su/auth/create.tmp.token.php', {
									mode: 'cors',
									cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
									credentials: 'include', // include, *same-origin, omit
									method: 'POST',
									body: JSON.stringify({
										resultType: 'json',
									}),
									headers: {
										'Content-Type': 'application/json'
									},
								}).then(stream => stream.json()).then(data2 => {
									//console.log(data2);
									//console.log('auth part 1 out of 2 done');
									
									if(data2.error_code == '0'){
										reject('create.tmp.token error_code = 0');
										return false;
									}
									
									fetch('https://sso.platform.bsrv.su/entity/scoe.action.session.createJWT?resultType=json', {
										mode: 'cors',
										cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
										credentials: 'include', // include, *same-origin, omit
										method: 'POST',
										body: JSON.stringify({
											token: data2.token,
										}),
										headers: {
											'Content-Type': 'application/json'
										},
									}).then(stream => stream.json()).then(data3 => {
										//console.log(data3);
										//console.log('auth part 2 out of 2 done');
										
										let token = data3.res.jwt_string;
										let user = data3.res.user;
										user.accessToken = token;
										user.roles = ['user'];
										user.is_admin = false;
										if(user.login == 'lexinzector'){
											user.roles.push('admin');
											user.is_admin = true;
										}
										
										localStorageSet('token', token);
										localStorageSet('user', user);
										axios.defaults.headers.common['Authorization'] = token;
										commit('auth_success', {token, user});
										resolve(data3.res);
										return true;
									}).catch(error => {
										console.log(error);
										commit('auth_error');
										localStorageRemove('token');
										localStorageRemove('user');
										delete axios.defaults.headers.common['Authorization'];
										reject(error);
										return false;
									});
								}).catch(error => {
									console.log(error);
									commit('auth_error');
									localStorageRemove('token');
									localStorageRemove('user');
									delete axios.defaults.headers.common['Authorization'];
									reject(error);
									return false;
								});
							}
						}).catch(error => {
							console.log(error);
							commit('auth_error');
							localStorageRemove('token');
							localStorageRemove('user');
							delete axios.defaults.headers.common['Authorization'];
							reject(erorr);
							return false;
						});
						
						
						
						//axios.post('https://sso.platform.bsrv.su/auth/check.auth.php', {
						//	resultType: 'json',
						//}, {
						//	withCredentials: true,
						//}).then(resp => {
						//	console.log(resp.data);
						//	console.log(params["store"].state.user);
						//	
						//	//let login_hash = {{user.login_hash | json_encode | raw}};
						//	//let is_guest = {{user.isGuest | json_encode | raw}};
						//	//if(responseText == '0' && is_guest) return false;
						//	//if(login_hash == responseText) return false;
						//	if(resp.data.error_code == '0'){
						//		// session expired or not exists. logout
						//		//dispatch('logout');
						//	} else {
						//		/*
						//		axios.post('https://sso.platform.bsrv.su/auth/create.tmp.token.php', {
						//			resultType: 'json',
						//		}).then(resp2 => {
						//			if(resp2.data.error_code == '0') return false;
						//			
						//			axios.post('https://iwpanel.g.bsrv.su/entity/scoe.action.session.createJWT?resultType=json', {
						//				token: resp2.data.token,
						//			}).then(resp3 => {
						//				localStorageSet('token', resp2.data.token);
						//				localStorageSet('user', JSON.stringify(resp3.data.user));
						//				axios.defaults.headers.common['Authorization'] = resp2.data.token;
						//				commit('auth_success', {
						//					token: resp2.data.token,
						//					user: resp3.data.user,
						//				});
						//				resolve(resp3);
						//			}).catch(err3 => {
						//				commit('auth_error');
						//				localStorageRemove('token');
						//				localStorageRemove('user');
						//				delete axios.defaults.headers.common['Authorization'];
						//				//deleteCookie('scoe_jwt');
						//				reject(err3);
						//			});
						//		}).catch(err2 => {
						//			commit('auth_error');
						//			localStorageRemove('token');
						//			localStorageRemove('user');
						//			delete axios.defaults.headers.common['Authorization'];
						//			//deleteCookie('scoe_jwt');
						//			reject(err2);
						//		});
						//		*/
						//	}
						//}).catch(err => {
						//	//commit('auth_error');
						//	//localStorageRemove('token');
						//	//localStorageRemove('user');
						//	//delete axios.defaults.headers.common['Authorization'];
						//	//deleteCookie('scoe_jwt');
						//	//reject(err);
						//	console.log(err);
						//});
					} else {
						resolve();
						return true;
					}
				});
			},
			login({commit}, user){
				return new Promise((resolve, reject) => {
					commit('auth_request');
					
					fetch('https://sso.platform.bsrv.su/auth/check.password.php', {
						mode: 'cors',
						cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
						credentials: 'include', // include, *same-origin, omit
						method: 'POST',
						body: JSON.stringify({
							login: user.login,
							password: user.password,
							resultType: 'json',
						}),
						headers: {
							'Content-Type': 'application/json'
						},
					}).then(stream => stream.json()).then(data => {
						if(data.error_code == '0'){
							commit('auth_error', data.error_str||'');
							localStorageRemove('token');
							localStorageRemove('user');
							delete axios.defaults.headers.common['Authorization'];
							//deleteCookie('scoe_jwt');
							reject('Неверная пара логин/пароль');
						}
						let token = data.token;
						let user = data.user;
						user.accessToken = token;
						user.roles = ['user'];
						user.is_admin = false;
						if(user.login == 'lexinzector'){
							user.roles.push('admin');
							user.is_admin = true;
						}
						
						fetch('https://sso.platform.bsrv.su/entity/scoe.action.session.updateJWT?resultType=json', {
							mode: 'cors',
							cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
							credentials: 'include', // include, *same-origin, omit
							method: 'POST',
							body: JSON.stringify({
								token,
							}),
							headers: {
								'Content-Type': 'application/json'
							},
						}).then(stream => stream.json()).then(data2 => {
							localStorageSet('token', token);
							localStorageSet('user', user);
							//axios.defaults.headers.common['Authorization'] = token;
							commit('auth_success', {token, user});
							resolve(data2);
							return true;
						}).catch(error2 => {
							commit('auth_error', error2);
							localStorageRemove('token');
							localStorageRemove('user');
							delete axios.defaults.headers.common['Authorization'];
							reject(error2);
							return false;
						});
					}).catch(error => {
						commit('auth_error', error);
						localStorageRemove('token');
						localStorageRemove('user');
						delete axios.defaults.headers.common['Authorization'];
						reject(error);
						return false;
					});
					
					/*//axios({url: 'https://iwpanel.g.bsrv.su/api/login', data: user, method: 'POST'})
					axios.post('https://sso.platform.bsrv.su/auth/check.password.php', {
						login: user.login,
						password: user.password,
						resultType: 'json',
					}).then(resp => {
						if(resp.data.error_code == '0'){
							commit('auth_error');
							localStorageRemove('token');
							localStorageRemove('user');
							delete axios.defaults.headers.common['Authorization'];
							//deleteCookie('scoe_jwt');
							reject('Неверная пара логин/пароль');
						}
						let token = resp.data.token;
						let user = resp.data.user;
						user.accessToken = token;
						user.roles = ['user'];
						user.is_admin = false;
						if(user.login == 'lexinzector'){
							user.roles.push('admin');
							user.is_admin = true;
						}
						axios.post('https://sso.platform.bsrv.su/entity/scoe.action.session.updateJWT?resultType=json', {
							token,
						}).then(resp2 => {
							localStorageSet('token', token);
							localStorageSet('user', user);
							//axios.defaults.headers.common['Authorization'] = token;
							commit('auth_success', {token, user});
							resolve(resp2);
						}).catch(err2 => {
							commit('auth_error');
							localStorageRemove('token');
							localStorageRemove('user');
							delete axios.defaults.headers.common['Authorization'];
							reject(err2);
						});
					}).catch(err => {
						commit('auth_error');
						localStorageRemove('token');
						localStorageRemove('user');
						delete axios.defaults.headers.common['Authorization'];
						reject(err);
					});*/
				});
			},
			logout({commit}){
				return new Promise((resolve, reject) => {
					fetch('https://sso.platform.bsrv.su/entity/scoe.action.session.logout?resultType=json', {
						mode: 'cors',
						cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
						credentials: 'include', // include, *same-origin, omit
						method: 'POST',
						body: JSON.stringify({
							request: true,
						}),
						headers: {
							'Content-Type': 'application/json'
						},
					}).then(stream => stream.json()).then(data => {
						commit('logout');
						localStorageRemove('token');
						localStorageRemove('user');
						delete axios.defaults.headers.common['Authorization'];
						resolve(data);
						return true;
					}).catch(error => {
						commit('auth_error', error);
						localStorageRemove('token');
						localStorageRemove('user');
						delete axios.defaults.headers.common['Authorization'];
						reject(error);
						return false;
					});
					
					/*axios.post('https://sso.platform.bsrv.su/entity/scoe.action.session.logout?resultType=json', {
						request: true,
					}).then(resp => {
						commit('logout');
						localStorageRemove('token');
						localStorageRemove('user');
						delete axios.defaults.headers.common['Authorization'];
						resolve(resp);
					}).catch(err => {
						commit('auth_error');
						localStorageRemove('token');
						localStorageRemove('user');
						delete axios.defaults.headers.common['Authorization'];
						reject(err);
					});*/
				});
			},
			register({commit}, user){
				return new Promise((resolve, reject) => {
					commit('auth_request');
					
					fetch('https://iwpanel.g.bsrv.su/api/register', {
						mode: 'cors',
						cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
						credentials: 'include', // include, *same-origin, omit
						method: 'POST',
						body: JSON.stringify({
							user,
						}),
						headers: {
							'Content-Type': 'application/json'
						},
					}).then(stream => stream.json()).then(data => {
						const token = data.token;
						const user = data.user;
						localStorageSet('token', token);
						localStorageSet('user', user);
						//axios.defaults.headers.common['Authorization'] = token;
						//setCookie('scoe_jwt', token, 1);
						commit('auth_success', {token: token, user: user});
						resolve(data);
						return true;
					}).catch(error => {
						commit('auth_error', error);
						localStorageRemove('token');
						localStorageRemove('user');
						delete axios.defaults.headers.common['Authorization'];
						reject(error);
						return false;
					});
					
					/*axios({url: 'https://iwpanel.g.bsrv.su/api/register', data: user, method: 'POST'})
					.then(resp => {
						const token = resp.data.token;
						const user = resp.data.user;
						localStorageSet('token', token);
						localStorageSet('user', user);
						//axios.defaults.headers.common['Authorization'] = token;
						//setCookie('scoe_jwt', token, 1);
						commit('auth_success', {token: token, user: user});
						resolve(resp);
					})
					.catch(err => {
						commit('auth_error', err);
						localStorageRemove('token');
						localStorageRemove('user');
						delete axios.defaults.headers.common['Authorization'];
						reject(err);
					});*/
				});
			},
		},
		mutations: {
			auth_request(state){
				state.authChecked = false;
				state.status = 'loading';
			},
			auth_success(state, data){
				state.authChecked = true;
				state.status = 'success';
				state.token = data.token;
				state.user = data.user;
			},
			auth_error(state){
				state.authChecked = false;
				state.status = 'error';
			},
			logout(state){
				state.authChecked = false;
				state.status = '';
				state.token = '';
				state.user = {};
			},
		},
		getters: {
			isLoggedIn: state => !!state.token,
			authStatus: state => state.status,
		},
		modules: {
			page: {
				namespaced: true,
				state: () => ({}),
				mutations: {},
			},
		},
	});
	
	return params;
};

/* Init data */
store.init = function(params){
	let store = params["store"];
	
	/* set name */
	//store.state.page.Layout.modalOpen = false;
	//store.state.page.Island.pageTitle = "Остров";
	
	return params;
}

/* Extends vue */
store.install = function(Vue, options){
	Vue.prototype.storeCommit = function(action, params){
		let arr = this.namespace.concat(action.split("/"));
		this.$store.commit(arr.join("/"), params);
	};
	
	Vue.prototype.storeDispatch = function(action, params){
		let arr = this.namespace.concat(action.split("/"));
		this.$store.dispatch(arr.join("/"), params);
	};
	
	Vue.prototype.getModel = function(){
		let arr = this.namespace.slice();
		let obj = this.$store.state;
		while(arr.length != 0){
			let key = arr.shift();
			if(obj[key] == undefined){
				obj = null;
				break;
			}
			obj = obj[key];
		}
		return obj;
	};
};

export default store;