<template>
	<div class="container pt-5 my-5">
		<header class="jumbotron">
			<h3>Рыночная площадь</h3>
		</header>
		<p>На <b>Рыночной площади</b> располагаются палатки <router-link to="/wiki/opis_econom8">Торговцев</router-link>. Только в этом месте можно купить, продать или обменять любой товар, будь-то вещи или ресурсы, существующий в игре. Более подробнее о работе торговцев можно прочитать в разделе <router-link to="/wiki/opis_econom11">Правила торговли</router-link>.</p>
	</div>
</template>

<style lang="css">

</style>

<script>
export default {
	name: 'WikiOpisRinok',
	computed: {
		currentUser(){
			return this.$store.state.user;
		},
	},
	/*mounted() {
		if (!this.currentUser) {
			this.$router.push('/login');
		}
	}*/
};
</script>