<template>
	<div class="pt-5">
		<div class="container">
			<div class="row mb-5">
				<div class="col-md-12 text-center">
					<div class="card h-100">
						<div class="card-header">
							<h3>Игровые миры</h3>
						</div>
						<div class="card-body">
							<b-overlay :show="serversOverlay" rounded="lg" variant="white">
								<div class="row mb-n5">
									<div class="col-md-4 mb-5" v-for="(server, pos) in servers">
										<b-overlay :show="server.overlay" rounded="lg" variant="light">
											<div class="card h-100">
												<div class="card-body">
													<h2 class="card-title">{{server.name}}<br/>({{server.api_name.toUpperCase()}})</h2>
													<p class="card-text">{{server.name}} ({{server.lang.toUpperCase()}})</p>
												</div>
												<div class="card-footer">
													<span @click="onShowInfo(pos)" class="btn btn-info">Инфо</span>
													<a :href="'https://'+server.domain" class="btn btn-primary">Играть</a>
												</div>
											</div>
										</b-overlay>
									</div>
								</div>
							</b-overlay>
						</div>
					</div>
				</div>
			</div>
			<div class="row mb-5">
				<div class="col-md-12 text-center">
					<div class="card h-100 border-info">
						<div class="card-header">
							<h3>Рекомендуемые игровые миры</h3>
						</div>
						<div class="card-body">
							<b-overlay :show="serversOverlay" rounded="lg" variant="white">
								<div class="row mb-n5">
									<div class="col-md-4 mb-5" v-for="(server, pos) in servers">
										<b-overlay :show="server.overlay" rounded="lg" variant="light">
											<div class="card h-100">
												<div class="card-body">
													<h2 class="card-title">{{server.name}}<br/>({{server.api_name.toUpperCase()}})</h2>
													<p class="card-text">{{server.name}} ({{server.lang.toUpperCase()}})</p>
												</div>
												<div class="card-footer">
													<span @click="onShowInfo(pos)" class="btn btn-info">Инфо</span>
													<a :href="'https://'+server.domain" target="_blank" class="btn btn-primary">Играть</a>
												</div>
											</div>
										</b-overlay>
									</div>
								</div>
							</b-overlay>
						</div>
					</div>
				</div>
			</div>
		</div>
		<v-dialog />
	</div>
</template>

<style lang="css" scoped>

</style>

<script>
export default {
	name: 'Servers',
	props: ['namespace'],
	data: () => ({
		serversOverlay: false,
		servers: [],
		serversCount: 0,
    }),
	buildStore: (state) => {
		if(state == null || state == undefined){
			state = {
				
			};
		}
		
		var res = {
			namespaced: true,
			state: () => {
				return state;
			},
			modules: {},
			mutations: {},
		}
		
		return res;
	},
	computed: {
		model(){
			return this.getModel();
		},
		currentUser(){
			return this.$store.state.user;
		},
		isLoggedIn(){
			return this.$store.getters.isLoggedIn;
		},
	},
	methods: {
		onShowInfo(pos){
			this.servers[pos].overlay = true;
			
			fetch('/api/servers/'+this.servers[pos].id+'?' + new URLSearchParams({
				//limit: 1,
			})).then(stream => stream.json()).then(data => {
				this.$modal.show('dialog', {
					title: 'Информация о мире',
					text: (
						'<div><b>Название:</b> '+data.name+' ('+data.api_name.toUpperCase()+')</div>'+
						'<div><b>Язык:</b> '+data.lang.toUpperCase()+'</div>'+
						'<div><b>Домен:</b> https://'+data.domain+'</div>'
					),
					buttons: [
						{
							title: 'Играть',
							handler: () => {
								location.href = 'https://'+data.domain;
							},
						},
						{
							title: 'Закрыть',
							handler: () => {
								this.$modal.hide('dialog');
							},
						},
					],
				});
				
				this.servers[pos].overlay = false;
			}).catch(error => {
				this.servers[pos].overlay = false;
				console.error(error);
			});
		},
	},
	beforeMount(){
		this.serversOverlay = true;
		fetch('/api/servers?' + new URLSearchParams({
			//limit: 1,
		})).then(stream => stream.json()).then(data => {
			this.serversCount = data.count;
			for(let i = 0; i < data.servers.length; i++){
				data.servers[i].overlay = false;
			}
			this.servers = data.servers;
			setTimeout(() => {
				this.serversOverlay = false;
			}, 1000);
		}).catch(error => {
			this.serversOverlay = false;
			console.error(error);
		});
	},
}
</script>