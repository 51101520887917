<template>
	<div class="container pt-5 my-5">
		<header class="jumbotron">
			<h3>Новости</h3>
		</header>
		<div class="row">
			<div class="col-md-12">
				<b-overlay :show="newsOverlay" rounded="lg" variant="white">
					<form v-on:submit.prevent="setPage(1)" style="margin-bottom: 20px;">
						<input type="text" v-model="newsPostsSearchText" class="form-control" placeholder="Поиск" />
					</form>
					<div class="row">
						<div class="col-md-12">
							<news-post
								v-bind:post="newsPost"
							></news-post>
						</div>
					</div>
				</b-overlay>
			</div>
		</div>
	</div>
</template>

<style scoped>
</style>

<script>
import NewsPost from '../components/NewsPost.vue';
import { decl1, decl2 } from '../assets/js/scripts.js';

export default
{
	name: 'NewsItem',
	props: ['namespace'],
	data(){
		return {
			newsOverlay: false,
			
			newsPostsSearchText: '',
			
			newsPost: [],
		}
	},
	methods: {
		loadPostData(id){
			this.newsOverlay = true;
			fetch('/api/news/'+id)
				.then(stream => stream.json())
				.then(item => {
					item.url = '/news/'+item.id;
					item.poster = '//iwstatic.g.bsrv.su/img/news/'+item.id+'/'+item.poster;
					item.datetime = new Date(item.ugmtime_public).format('d.m.Y');
					item.commentsText = parseInt(item.comments) > 0 ? item.comments+' '+decl1(item.comments, ['комментарий', 'комментария', 'комментариев']) : 'Комментариев нет';
					item.viewsText = parseInt(item.views) > 0 ? item.views+' '+decl1(item.views, ['просмотр', 'просмотра', 'просмотров']) : 'Просмотров нет';
					item.likesText = parseInt(item.likes) > 0 ? item.likes+' '+decl1(item.likes, ['лайк', 'лайка', 'лайков']) : 'Лайков нет';
					
					this.newsPost = item;
					
					setTimeout(() => {
						this.newsOverlay = false;
					}, 1000);
				})
				.catch(error => console.error(error));
		},
	},
	beforeMount(){
		this.loadPostData(this.$route.params.id);
	},
	buildStore: (state) => {
		if (state == null || state == undefined){
			state = {
				pageTitle: "Username2",
			};
		}
		
		var res = {
			namespaced: true,
			state: () => { return state; },
			modules: {
				
			},
			mutations: {
			},
		}
		
		return res;
	},
	computed: {
		model(){
			return this.getModel();
		},
	},
	components: {
		NewsPost: NewsPost,
	},
}
</script>